import React, { useState, useEffect, useRef, Fragment } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import BaseButton from "../BaseButton/BaseButton";
import { useMediaQuery } from "react-responsive";
import lockIcon from "../../images/lock_icon.svg";
import lockQMarkIcon from "../../images/lock_icon_q_mark.svg";
import lockEmailSentIcon from "../../images/lock_icon_email_sent.svg";
import userIcon from "../../images/user_icon.svg";
import userIconSuccess from "../../images/user_icon_success.svg";
import SmartZeeLogo from "../../images/smartzee_logo.svg";
import baseURL from "../../baseURL";
import { version } from "../../../package.json";
import "./Auth.css";

const Auth = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [pwInputType, setPwInputType] = useState("password");
  const [pwInputTypeConfirm, setPwInputTypeConfirm] = useState("password");
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [signUpIsDisabled, setSignUpIsDisabled] = useState(false);
  const [signUpIsSuccess, setSignUpIsSuccess] = useState(false);
  const [isForgot, setIsForgot] = useState(false);

  const loginBtn = useRef(null);

  const SITE_KEY = "6Lei5xsfAAAAAOJ8LN47N0XTDMVFGio3eg47mDCL";

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1280 });

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      () => {
        console.log("Script loaded!");
      }
    );
  }, []);

  // Detect if URL action is sign up from landing page click
  useEffect(() => {
    let signupAction = new URLSearchParams(window.location.search).get(
      "action"
    );
    if (signupAction) {
      setIsSignUp(true);
    }
  }, []);

  const handleNameOnChange = (val, setter) => {
    setSignUpIsSuccess(false);
    setter(val);
    if (val.length > 20) {
      setErrMsg("Name cannot exceed 20 characters!");
      setSignUpIsDisabled(true);
    } else {
      setErrMsg("");
      if (!validateEmail(email) || password !== confirmPassword) {
        setSignUpIsDisabled(true);
      } else {
        setSignUpIsDisabled(false);
      }
    }
  };

  const removeWhitespace = (string) => {
    return string.replace(" ", "");
  };

  const validateEmail = (val) => {
    let emailValidate =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    if (emailValidate.test(val)) {
      return true;
    } else {
      return false;
    }
  };

  const handleEmailOnChange = (e) => {
    let val = removeWhitespace(e.target.value);
    setEmail(val);
    setSignUpIsSuccess(false);

    if (!validateEmail(val)) {
      setErrMsg("Please enter a valid email!");
      setSignUpIsDisabled(true);
    } else {
      setErrMsg("");
    }
  };

  const handlePwOnChange = (e) => {
    setErrMsg("");
    setPassword(e.target.value);
    if (isSignUp) {
      setSignUpIsSuccess(false);
      if (e.target.value.length < 8) {
        setErrMsg("Password must be at least 8 characters");
        setSignUpIsDisabled(true);
      } else if (e.target.value !== confirmPassword && confirmPassword !== "") {
        setErrMsg("Passwords do not match!");
        setSignUpIsDisabled(true);
      } else {
        setErrMsg("");
        if (
          !validateEmail(email) ||
          e.target.value.length < 8 ||
          e.target.value !== confirmPassword
        ) {
          setSignUpIsDisabled(true);
        } else {
          setSignUpIsDisabled(false);
        }
      }
    }
  };

  const handleConfirmPwOnChange = (e) => {
    setErrMsg("");
    setSignUpIsSuccess(false);
    setConfirmPassword(e.target.value);
    if (e.target.value !== password) {
      setErrMsg("Passwords do not match!");
      setSignUpIsDisabled(true);
    } else {
      setErrMsg("");
      if (!validateEmail(email)) {
        setSignUpIsDisabled(true);
      } else {
        setSignUpIsDisabled(false);
      }
    }
  };

  const postSignUp = async (accessToken) => {
    try {
      const url = new URL("./api/v1/accounts/signup", baseURL);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          recaptcha: accessToken,
        }),
      });

      if (!response.ok) {
        setLoading(false);
        setSuccess(false);
        setError(true);

        let result = await response.json();
        const breakText = (arr) =>
          arr.map((item, index) => (
            <span key={index}>
              {item && <br />}
              {item}
            </span>
          ));
        if (result.non_field_errors) {
          let message = result.non_field_errors[0];
          let messageArr = message.split("|");
          const lines = breakText(messageArr);
          setErrMsg(lines);
        }
        if (result.detail) {
          setErrMsg(result.detail);
        }
        if (result.password) {
          let message = result.password[0];
          let messageArr = message.split("|");
          const lines = breakText(messageArr);
          setErrMsg(lines);
        }
        setTimeout(() => {
          setError(false);
          setErrMsg("");
        }, 3000);
      } else {
        setLoading(false);
        setSuccess(true);
        setSignUpIsSuccess(true);
        let result = await response.json();
        setErrMsg(
          `Welcome to SmartZee, ${
            result.first_name || result.email
          }. \nPlease check your email for a confirmation link where you need to click on the link inside to verify your email. If you can't find it in your Inbox,
          please check Spam / Junk folder`
        );
        setTimeout(() => {
          setError(false);
          setSuccess(false);
        }, 1000);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setSignUpIsDisabled(true);
        setPwInputType("password");
        setPwInputTypeConfirm("password");
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      setSuccess(false);
      setErrMsg(
        "Failed to sign up! Please check your connection and try again."
      );
      setTimeout(() => {
        setError(false);
        setErrMsg("");
      }, 3000);
      console.log(error);
    }
  };

  const postLogin = async (accessToken) => {
    try {
      const url = new URL("./auth/login", baseURL);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          recaptcha: accessToken,
        }),
      });

      if (!response.ok) {
        setLoading(false);
        setSuccess(false);
        setError(true);
        setErrMsg("Login Failed!");
        setTimeout(() => {
          setError(false);
          setErrMsg("");
        }, 3000);
      } else {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setError(false);
          setErrMsg("");
        }, 5000);
        let result = await response.json();
        if (rememberMe) {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("userId");
          localStorage.setItem("token", result.access);
          localStorage.setItem("userId", result.userId);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          sessionStorage.setItem("token", result.access);
          sessionStorage.setItem("userId", result.userId);
        }
        setIsSignedIn(true);
        props.setToken(result.access);
        props.setUserId(result.userId);
        setToken(result.access);
        setUserId(result.userId);
        setTimeout(() => {
          props.authenticate();
        }, 700);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      setSuccess(false);
      setErrMsg("Login Failed!");
      setTimeout(() => {
        setError(false);
        setErrMsg("");
      }, 3000);
      console.log(error);
    }
  };

  const handleLoginSubmit = () => {
    setLoading(true);
    setError(false);
    setSuccess(false);

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, {
          action: isSignUp ? "account" : isForgot ? "password_reset" : "login",
        })
        .then((accessToken) => {
          isSignUp
            ? postSignUp(accessToken)
            : isForgot
            ? postForgotPw(accessToken)
            : postLogin(accessToken);
        });
    });
  };

  const handleShowPw = () => {
    if (pwInputType === "password") {
      setPwInputType("text");
    } else {
      setPwInputType("password");
    }
  };

  const handleShowPwConfirm = () => {
    if (pwInputTypeConfirm === "password") {
      setPwInputTypeConfirm("text");
    } else {
      setPwInputTypeConfirm("password");
    }
  };

  // Submit login when clicking Enter
  const handleKeyboardSubmit = (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      console.log("Enter clicked!");
      console.log(loginBtn);
      loginBtn.current.click();
    }
  };

  const postForgotPw = async (accessToken) => {
    setLoading(true);
    setError(false);
    setSuccess(false);

    try {
      const url = new URL("./api/v1/accounts/password/reset", baseURL);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          recaptcha: accessToken,
        }),
      });

      if (!response.ok) {
        setLoading(false);
        setSuccess(false);
        setError(true);
        setErrMsg("Failed to send reset password email!");
        setTimeout(() => {
          setError(false);
          setErrMsg("");
        }, 3000);
      } else {
        setLoading(false);
        setSuccess(true);
        setSignUpIsSuccess(true);
        setErrMsg(
          "Reset password email sent! Please check your inbox and click on the link inside to reset your password. If you can't find it in your Inbox, please check Spam / Junk folder"
        );
        setTimeout(() => {
          setSuccess(false);
          setError(false);
        }, 5000);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      setSuccess(false);
      setErrMsg("Failed to send reset password email!");
      setTimeout(() => {
        setError(false);
        setErrMsg("");
      }, 3000);
      console.log(error);
    }
  };

  return (
    <div
      id='auth-page'
      className='container'
      style={{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}>
      <div className='columns auth-wrapper'>
        <div className='column is-half zee-logo'>
          <img src={SmartZeeLogo} alt='smartzee logo' />
        </div>
        <div className='divider-circle'>
          <img
            className='zee-icon'
            src={
              isSignUp && !signUpIsSuccess
                ? userIcon
                : isSignUp && signUpIsSuccess
                ? userIconSuccess
                : isForgot && !signUpIsSuccess
                ? lockQMarkIcon
                : isForgot && signUpIsSuccess
                ? lockEmailSentIcon
                : lockIcon
            }
            alt={isSignUp ? "sign up icon" : "login icon"}
          />
        </div>
        <div className='divider-line'></div>
        <div className='column is-half login-form'>
          <div
            id='sign-up-form'
            style={{ display: signUpIsSuccess ? "none" : "block" }}>
            {isSignUp && (
              <>
                <div className='field is-horizontal auth-email label-on-top'>
                  <div
                    className='field-label pt-3 pb-2'
                    style={{
                      flexBasis: "auto",
                      flexGrow: "unset",
                    }}>
                    <label className='label'>
                      First Name{" "}
                      <span style={{ fontSize: "10px", color: "#bbbbbb" }}>
                        {" "}
                        (Optional)
                      </span>
                      <span>
                        <OverlayTrigger
                          trigger='click'
                          key='right'
                          placement={isTablet ? "bottom" : "right"}
                          overlay={
                            <Popover id='info-popover'>
                              <Popover.Header as='h3'>
                                Your First Name
                              </Popover.Header>
                              <Popover.Body>
                                First Name is optional. However, you may add a
                                name with a max length of 20 characters.
                              </Popover.Body>
                            </Popover>
                          }
                          rootClose>
                          <span className='info-circle'>i</span>
                        </OverlayTrigger>
                      </span>
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='control is-expanded'>
                        <input
                          id='auth-first-name-input'
                          className={"input " + (error && "is-danger")}
                          type='text'
                          onChange={(e) =>
                            handleNameOnChange(e.target.value, setFirstName)
                          }
                          onKeyUp={(e) => handleKeyboardSubmit(e)}
                          value={firstName}
                          maxLength='20'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='field is-horizontal auth-email label-on-top'>
                  <div
                    className='field-label pt-3 pb-2'
                    style={{
                      flexBasis: "auto",
                      flexGrow: "unset",
                    }}>
                    <label className='label'>
                      Last Name{" "}
                      <span style={{ fontSize: "10px", color: "#bbbbbb" }}>
                        {" "}
                        (Optional)
                      </span>
                      <span>
                        <OverlayTrigger
                          trigger='click'
                          key='right'
                          placement={isTablet ? "bottom" : "right"}
                          overlay={
                            <Popover id='info-popover'>
                              <Popover.Header as='h3'>
                                Your Last Name
                              </Popover.Header>
                              <Popover.Body>
                                Last Name is optional. However, you may add a
                                name with a max length of 20 characters.
                              </Popover.Body>
                            </Popover>
                          }
                          rootClose>
                          <span className='info-circle'>i</span>
                        </OverlayTrigger>
                      </span>
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='control is-expanded'>
                        <input
                          id='auth-last-name-input'
                          className={"input " + (error && "is-danger")}
                          type='text'
                          onChange={(e) =>
                            handleNameOnChange(e.target.value, setLastName)
                          }
                          onKeyUp={(e) => handleKeyboardSubmit(e)}
                          value={lastName}
                          maxLength='20'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className='field is-horizontal auth-email label-on-top'>
              <div
                className='field-label pt-3 pb-2'
                style={{
                  flexBasis: "auto",
                  flexGrow: "unset",
                }}>
                <label className='label'>
                  Email
                  {isSignUp && (
                    <span>
                      <OverlayTrigger
                        trigger='click'
                        key='right'
                        placement={isTablet ? "bottom" : "right"}
                        overlay={
                          <Popover id='info-popover'>
                            <Popover.Header as='h3'>Your email</Popover.Header>
                            <Popover.Body>
                              Please use a valid email address. This email will
                              be used later to validate your account.
                            </Popover.Body>
                          </Popover>
                        }
                        rootClose>
                        <span className='info-circle'>i</span>
                      </OverlayTrigger>
                    </span>
                  )}
                </label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control is-expanded'>
                    <input
                      id='auth-email-input'
                      className={"input " + (error && "is-danger")}
                      onChange={handleEmailOnChange}
                      onKeyUp={(e) => handleKeyboardSubmit(e)}
                      value={email}
                    />
                  </div>
                </div>
              </div>
            </div>
            {!isForgot && (
              <div className='field is-horizontal auth-pw label-on-top'>
                <div
                  className='field-label pt-3 pb-2'
                  style={{
                    flexBasis: "auto",
                    flexGrow: "unset",
                  }}>
                  <label className='label'>
                    Password
                    {isSignUp && (
                      <span>
                        <OverlayTrigger
                          trigger='click'
                          key='right'
                          placement={isTablet ? "bottom" : "right"}
                          overlay={
                            <Popover id='info-popover'>
                              <Popover.Header as='h3'>
                                Your Password
                              </Popover.Header>
                              <Popover.Body>
                                Please create a non numeric password that at
                                least contains letters only or a mix of letters
                                and numbers, with a min length of 8 characters
                                and a max length of 20 characters. You may use
                                whitespace and special characters.
                              </Popover.Body>
                            </Popover>
                          }
                          rootClose>
                          <span className='info-circle'>i</span>
                        </OverlayTrigger>
                      </span>
                    )}
                  </label>
                </div>
                <div className='field-body'>
                  <div className='field'>
                    <div className='control is-expanded'>
                      <input
                        id='auth-pw-input'
                        className={"input " + (error && "is-danger")}
                        type={pwInputType}
                        maxLength='20'
                        onChange={handlePwOnChange}
                        onKeyUp={(e) => handleKeyboardSubmit(e)}
                        value={password}
                        onPaste={(e) => {
                          if (
                            pwInputType === "password" ||
                            (isSignUp && pwInputTypeConfirm === "password")
                          ) {
                            e.preventDefault();
                            setErrMsg("Please write your password!");
                          }
                        }}
                      />
                      <svg
                        id='auth-toggle-show-pw-icon'
                        width='38'
                        height='38'
                        viewBox='0 0 38 38'
                        fill='none'
                        className='eye-icon'
                        alt='Show password'
                        onClick={handleShowPw}
                        xmlns='http://www.w3.org/2000/svg'>
                        <rect
                          width='38'
                          height='38'
                          viewBox='0 0 38 38'
                          x='0'
                          y='0'
                          fill='none'></rect>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M12 5C7 5 2.73 8.11 1 12.5C2.73 16.89 7 20 12 20C17 20 21.27 16.89 23 12.5C21.27 8.11 17 5 12 5ZM12 17.5C9.24 17.5 7 15.26 7 12.5C7 9.74 9.24 7.5 12 7.5C14.76 7.5 17 9.74 17 12.5C17 15.26 14.76 17.5 12 17.5ZM12 9.5C10.34 9.5 9 10.84 9 12.5C9 14.16 10.34 15.5 12 15.5C13.66 15.5 15 14.16 15 12.5C15 10.84 13.66 9.5 12 9.5Z'
                          fill={
                            pwInputType === "password" ? "#757575" : "#1DE9B6"
                          }
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isSignUp && (
              <div className='field is-horizontal auth-pw label-on-top mb-4'>
                <div
                  className='field-label pt-3 pb-2'
                  style={{
                    flexBasis: "auto",
                    flexGrow: "unset",
                  }}>
                  <label className='label'>Confirm Password</label>
                </div>
                <div className='field-body'>
                  <div className='field'>
                    <div className='control is-expanded'>
                      <input
                        id='auth-confirm-pw-input'
                        className={"input " + (error && "is-danger")}
                        type={pwInputTypeConfirm}
                        maxLength='20'
                        onChange={handleConfirmPwOnChange}
                        onKeyUp={(e) => handleKeyboardSubmit(e)}
                        value={confirmPassword}
                        onPaste={(e) => {
                          if (
                            pwInputType === "password" ||
                            pwInputTypeConfirm === "password"
                          ) {
                            e.preventDefault();
                            setErrMsg("Please write your password once again!");
                          }
                        }}
                      />
                      <svg
                        width='38'
                        height='38'
                        viewBox='0 0 38 38'
                        fill='none'
                        className='eye-icon'
                        alt='Show password'
                        onClick={handleShowPwConfirm}
                        xmlns='http://www.w3.org/2000/svg'>
                        <rect
                          width='38'
                          height='38'
                          viewBox='0 0 38 38'
                          x='0'
                          y='0'
                          fill='none'></rect>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M12 5C7 5 2.73 8.11 1 12.5C2.73 16.89 7 20 12 20C17 20 21.27 16.89 23 12.5C21.27 8.11 17 5 12 5ZM12 17.5C9.24 17.5 7 15.26 7 12.5C7 9.74 9.24 7.5 12 7.5C14.76 7.5 17 9.74 17 12.5C17 15.26 14.76 17.5 12 17.5ZM12 9.5C10.34 9.5 9 10.84 9 12.5C9 14.16 10.34 15.5 12 15.5C13.66 15.5 15 14.16 15 12.5C15 10.84 13.66 9.5 12 9.5Z'
                          fill={
                            pwInputTypeConfirm === "password"
                              ? "#757575"
                              : "#1DE9B6"
                          }
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isSignUp && !isForgot && (
              <>
                <div className='field is-horizontal auth-remember'>
                  <div
                    className='field-label pt-3 pb-2'
                    style={{
                      flexBasis: "auto",
                      flexGrow: "unset",
                    }}>
                    <label
                      className='label has-text-white'
                      style={{ fontWeight: "normal", fontSize: "14px" }}>
                      Remember me
                    </label>
                  </div>
                  <input
                    id='auth-remember-me-input'
                    className='checkbox'
                    type='checkbox'
                    onChange={() => setRememberMe(!rememberMe)}
                    checked={rememberMe}
                    onKeyUp={(e) => handleKeyboardSubmit(e)}
                  />
                </div>
                <div className='field is-horizontal mb-4'>
                  <span
                    id='auth-forgot-pw-link'
                    className='signup forgot-pw'
                    onClick={() => {
                      setIsForgot(!isForgot);
                      setEmail("");
                    }}>
                    Forgot password?
                  </span>
                </div>
              </>
            )}
            <div className='field is-horizontal login-button-wrapper'>
              <div className={`field ${isSignUp || isForgot ? "pt-3" : null}`}>
                <p className='control is-expanded'>
                  <BaseButton
                    id='auth-submit-btn'
                    classes='px-6 has-text-weight-semibold '
                    onClick={handleLoginSubmit}
                    loading={loading}
                    success={success}
                    error={error}
                    disabled={
                      isSignUp && !isForgot
                        ? signUpIsDisabled ||
                          !validateEmail(email) ||
                          password.length < 8
                        : !isSignUp && !isForgot
                        ? !validateEmail(email) || password.length < 8
                        : isForgot
                        ? !validateEmail(email)
                        : null
                    }
                    text={
                      isSignUp
                        ? "SIGN UP"
                        : isForgot
                        ? "RESET PASSWORD"
                        : "LOGIN"
                    }
                    loginBtn={loginBtn}
                  />
                  <span className={`splash ${isSignedIn && "expanded"}`}></span>
                </p>
              </div>
            </div>
            {!isForgot ? (
              <div className='field is-horizontal signup-wrapper'>
                <span
                  id='auth-signup-login-link'
                  className='signup'
                  onClick={() => {
                    setIsSignUp(!isSignUp);
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPassword("");
                    setConfirmPassword("");
                    setErrMsg("");
                    setSignUpIsDisabled(true);
                    setPwInputType("password");
                    setPwInputTypeConfirm("password");
                    setSignUpIsSuccess(false);
                    window.history.pushState({}, null, "/");
                  }}>
                  {isSignUp ? "Login" : "Sign Up"}
                </span>
                <span className='field google-recaptcha-text'>
                  This site is protected by reCAPTCHA and the Google &nbsp;
                  <a
                    style={{ display: "inline-block" }}
                    href='https://policies.google.com/privacy'>
                    Privacy Policy
                  </a>{" "}
                  and &nbsp;
                  <a href='https://policies.google.com/terms'>
                    Terms of Service
                  </a>{" "}
                  apply.
                </span>
              </div>
            ) : (
              <div className='field is-horizontal signup-wrapper'>
                <span
                  className='signup forgot'
                  onClick={() => {
                    setIsForgot(!isForgot);
                    setEmail("");
                    setErrMsg("");
                  }}>
                  Login
                </span>
                <span className='field google-recaptcha-text'>
                  This site is protected by reCAPTCHA and the Google &nbsp;
                  <a
                    style={{ display: "inline-block" }}
                    href='https://policies.google.com/privacy'>
                    Privacy Policy
                  </a>{" "}
                  and &nbsp;
                  <a href='https://policies.google.com/terms'>
                    Terms of Service
                  </a>{" "}
                  apply.
                </span>
              </div>
            )}
          </div>
          {errMsg !== "" ? (
            <div className='field'>
              <p
                id='auth-err-msg'
                style={{ fontSize: signUpIsSuccess && "14px" }}
                className={`auth-msg field-body help ${
                  signUpIsSuccess ? "is-success" : "is-danger"
                }`}>
                {errMsg}
              </p>
            </div>
          ) : (
            <p className='auth-msg help'>&nbsp;</p>
          )}
          {signUpIsSuccess && (
            <div style={{ textAlign: "left" }}>
              <span
                className='signup'
                onClick={() => {
                  isForgot ? setIsForgot(!isForgot) : setIsSignUp(!isSignUp);
                  setFirstName("");
                  setLastName("");
                  setEmail("");
                  setPassword("");
                  setConfirmPassword("");
                  setErrMsg("");
                  setSignUpIsDisabled(true);
                  setError(false);
                  setSuccess(false);
                  setPwInputType("password");
                  setPwInputTypeConfirm("password");
                  setSignUpIsSuccess(false);
                }}>
                Login
              </span>
            </div>
          )}
        </div>
      </div>
      <p className='app-package-version'>{version}</p>
    </div>
  );
};

export default Auth;
