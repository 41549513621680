import React, { useState, useEffect, Fragment } from "react";
import Modal from "../Modal/Modal";
import MobileModal from "../MobileModal/MobileModal";
import {
  validateHexHelper,
  validateLabelHelper,
  validateSNHelper,
} from "../../utils/helperfuncs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import plusIcon from "../../images/plus_icon.svg";
import baseURL from "../../baseURL";
import "./AddDevice.css";

const AddDevice = (props) => {
  const getInitialState = () => {
    const value =
      props.deviceListData && props.deviceListData.length !== 0
        ? props.deviceListData[0].id
        : props.virtualDeviceId;
    return value;
  };

  const [addedDeviceType, setAddedDeviceType] = useState("zee");
  const [addedDeviceParentId, setAddedDeviceParentId] = useState(null);
  const [addedDeviceLabel, setAddedDeviceLabel] = useState("");
  const [addedDeviceSN, setAddedDeviceSN] = useState("");
  const [addedDeviceMeterType, setAddedDeviceMeterType] = useState("P1");
  const [addedDeviceMeterSubType, setAddedDeviceMeterSubType] =
    useState("0x201");
  const [isModal, setIsModal] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [validateLabel, setValidateLabel] = useState(false);
  const [validateLabelErrMsg, setValidateLabelErrMsg] = useState("");
  const [validateSN, setValidateSN] = useState(false);
  const [validateSNErrMsg, setValidateSNErrMsg] = useState("");
  const [showMobileModal, setShowMobileModal] = useState(false);

  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const userId =
    localStorage.getItem("userId") || sessionStorage.getItem("userId");

  // elgris Products
  const meterTypes = [
    {
      name: "P1",
      id: 1,
    },
    {
      name: "ILM",
      id: 2,
    },
    {
      name: "SM LAN",
      id: 3,
    },
  ];

  const P1Family = [
    { name: "P1 EM WiFi", id: "0x201", remote: false },
    { name: "P1 EM Ethernet", id: "0x211", remote: false },
    { name: "P1 ZERO", id: "0x220", remote: true },
  ];
  const ILMFamily = [
    { name: "ILM WiFi", id: "0x401", remote: false },
    { name: "ILM WiFi/LTE", id: "0x412", remote: false },
    { name: "ILM WiFi CB", id: "0x421", remote: true },
    { name: "ILM WiFi/LTE CB", id: "0x422", remote: true },
  ];
  const SMLAN = [{ name: "SM LAN", id: "0x501", remote: false }];
  const ZERO = ["0x601"];
  const ELWA = ["0x701"];
  const BASIC = ["0x801"];
  const EMSBattery = ["0x901"];

  // let finalSMType;
  // switch (addedDeviceMeterSubType) {
  //   case "P1 EM WiFi":
  //     finalSMType = "0x201";
  //     break;

  //   case "P1 EM Ethernet":
  //     finalSMType = "0x211";
  //     break;

  //   case "P1 ZERO":
  //     finalSMType = "0x220";
  //     break;

  //   case "ILM WiFi":
  //     finalSMType = "0x401";
  //     break;

  //   case "ILM WiFi/LTE":
  //     finalSMType = "0x412";
  //     break;

  //   case "ILM WiFi CB":
  //     finalSMType = "0x421";
  //     break;

  //   case "ILM WiFi/LTE CB":
  //     finalSMType = "0x422";
  //     break;

  //   case "SM LAN":
  //     finalSMType = "0x501";
  //     break;

  //   default:
  //     finalSMType = "1234";
  // }

  useEffect(() => {
    setAddedDeviceParentId(getInitialState);
  }, [props.deviceListData]);

  const addNewDevice = async () => {
    setFetchLoading(true);
    setFetchError(false);
    setFetchSuccess(false);
    try {
      const url =
        addedDeviceType === "zee"
          ? new URL(`./api/v1/users/${userId}/devices`, baseURL)
          : new URL(
              `./api/v1/devices/${addedDeviceParentId}/smartmeters`,
              baseURL
            );

      const body =
        addedDeviceType === "zee"
          ? JSON.stringify({
              name: addedDeviceLabel,
              serial_number: addedDeviceSN,
              device_type: "0x601",
              user: props.userIdAdminOverride || userId,
            })
          : JSON.stringify({
              device: addedDeviceParentId,
              name: addedDeviceLabel,
              serial_number: addedDeviceSN,
              sm_type:
                addedDeviceParentId === props.virtualDeviceId
                  ? addedDeviceMeterSubType
                  : "0x501",
            });
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: body,
      });
      if (response.status !== 201) {
        let data = await response.json();
        if (
          data.serial_number[0].includes(
            "with this serial number already exists."
          )
        ) {
          props.setSubmitErrMsg("Please check submitted serial number");
        }
        setFetchLoading(false);
        setFetchError(true);
        setTimeout(() => {
          setFetchSuccess(false);
          setFetchError(false);
          props.setSubmitErrMsg("");
        }, 5000);
      } else {
        let data = await response.json();
        setFetchLoading(false);
        setFetchSuccess(true);
        props.isMobile
          ? setTimeout(() => {
              props.setIsSuccess(true);
            }, 500)
          : props.setIsSuccess(true);
        setAddedDeviceLabel("");
        setAddedDeviceSN("");
        handleCloseModal();
        props.getDeviceList();
        props.openZeeId !== null && props.getSMetersList(props.openZeeId);
        setTimeout(() => {
          setFetchSuccess(false);
          setFetchError(false);
          props.setIsSuccess(false);
        }, 5000);
      }
    } catch (error) {
      setFetchLoading(false);
      setFetchError(true);
      setTimeout(() => {
        setFetchError(false);
      }, 3000);
    }
  };

  const handleCloseModal = () => {
    !props.isMobile ? setIsModal(!isModal) : setShowMobileModal(false);
    setValidateLabel(false);
    setValidateSN(false);
    setValidateLabelErrMsg("");
    setValidateSNErrMsg("");
    setAddedDeviceType("zee");
    setAddedDeviceLabel("");
    setAddedDeviceParentId(getInitialState);
    setAddedDeviceSN("");
    // Clear button to defaults
    setFetchLoading(false);
    setFetchSuccess(false);
    setFetchError(false);
    props.setSubmitErrMsg("");
  };

  const handleAddLabel = (e) => {
    setAddedDeviceLabel(e.target.value);
    validateLabelHelper(
      e.target.value,
      setValidateLabel,
      setValidateLabelErrMsg
    );
  };

  const handleAddSN = (e) => {
    setAddedDeviceSN(e.target.value);
    validateSNHelper(e.target.value, setValidateSN, setValidateSNErrMsg);
  };

  const JSXContent = (
    <Fragment>
      <div className='columns'>
        <div className='column flex-start'>
          <label className='label'>Device Variant</label>
        </div>
        <div className='column'>
          <div className='select is-fullwidth'>
            <select
              id='home-add-device-modal-device-type-sel'
              className='input load-control-input transparent-input'
              value={addedDeviceType}
              onInput={(e) => setAddedDeviceType(e.target.value)}>
              <option value='zee'>Zee</option>
              <option value='sm'>Smart Meter</option>
            </select>
          </div>
        </div>
        <div className='column'></div>
      </div>
      <div
        className='columns'
        style={{
          visibility: addedDeviceType === "sm" ? "visible" : "hidden",
          maxHeight: addedDeviceType === "sm" ? "200px" : "0",
          padding: addedDeviceType === "sm" ? "10px 0" : "0",
          transition: "all 0.2s ease-in-out",
          minHeight: "12px",
        }}>
        <div className='column flex-start'>
          <label className='label'>Device Parent</label>
        </div>
        <div className='column'>
          <div className='select is-fullwidth'>
            <select
              id='home-add-device-modal-device-parent-sel'
              className='input load-control-input transparent-input'
              value={addedDeviceParentId}
              onInput={(e) => (
                setAddedDeviceParentId(parseInt(e.target.value)),
                e.target.value === props.virtualDeviceId &&
                  setAddedDeviceMeterType("P1")
              )}>
              {props.deviceListData.map((obj, key) => {
                return (
                  <option key={key} value={obj.id}>
                    {obj.name}
                  </option>
                );
              })}
              <option value={props.virtualDeviceId}>None</option>
            </select>
          </div>
        </div>
        <div className='column'></div>
      </div>
      {
        <Fragment>
          <div
            className='columns'
            style={{
              visibility:
                addedDeviceType === "sm" &&
                addedDeviceParentId === props.virtualDeviceId
                  ? "visible"
                  : "hidden",
              maxHeight:
                addedDeviceType === "sm" &&
                addedDeviceParentId === props.virtualDeviceId
                  ? "200px"
                  : "0",
              padding:
                addedDeviceType === "sm" &&
                addedDeviceParentId === props.virtualDeviceId
                  ? "10px 0"
                  : "0",
              transition: "all 0.2s ease-in-out",
              minHeight: "24px",
            }}>
            <div className='column flex-start'>
              <label className='label'>Device Type</label>
            </div>
            <div className='column'>
              <div className='select is-fullwidth'>
                <select
                  id='home-add-device-modal-device-parent-sel'
                  className='input load-control-input transparent-input'
                  value={addedDeviceMeterType}
                  onInput={(e) => (
                    setAddedDeviceMeterType(e.target.value),
                    e.target.value === "P1"
                      ? setAddedDeviceMeterSubType("0x201")
                      : e.target.value === "ILM"
                      ? setAddedDeviceMeterSubType("0x401")
                      : e.target.value === "SM LAN"
                      ? setAddedDeviceMeterSubType("0x501")
                      : null
                  )}>
                  {meterTypes.map((obj, key) => {
                    return (
                      <option key={key} value={obj.name}>
                        {obj.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className='column'></div>
          </div>
          <div
            className='columns'
            style={{
              visibility:
                addedDeviceType === "sm" &&
                addedDeviceParentId === props.virtualDeviceId
                  ? "visible"
                  : "hidden",
              maxHeight:
                addedDeviceType === "sm" &&
                addedDeviceParentId === props.virtualDeviceId
                  ? "200px"
                  : "0",
              padding:
                addedDeviceType === "sm" &&
                addedDeviceParentId === props.virtualDeviceId
                  ? "10px 0"
                  : "0",
              transition: "all 0.2s ease-in-out",
            }}>
            <div className='column flex-start'>
              <label className='label'>Device Sub-Type</label>
            </div>
            <div className='column'>
              <div className='select is-fullwidth'>
                <select
                  id='home-add-device-modal-device-parent-sel'
                  className='input load-control-input transparent-input'
                  value={addedDeviceMeterSubType}
                  disabled={addedDeviceMeterType === "SM LAN"}
                  onInput={(e) => (
                    setAddedDeviceMeterSubType(e.target.value),
                    console.log(e.target.value)
                  )}>
                  {addedDeviceMeterType === "P1" ? (
                    P1Family.map((obj, key) => {
                      return (
                        <option key={key} value={obj.id}>
                          {obj.name}
                        </option>
                      );
                    })
                  ) : addedDeviceMeterType === "ILM" ? (
                    ILMFamily.map((obj, key) => {
                      return (
                        <option key={key} value={obj.id}>
                          {obj.name}
                        </option>
                      );
                    })
                  ) : addedDeviceMeterType === "SM LAN" ? (
                    <option value='0'>N/A</option>
                  ) : null}
                </select>
              </div>
            </div>
            <div className='column'></div>
          </div>
        </Fragment>
      }
      <div className='columns'>
        <div className='column flex-start'>
          <label className='label'>Device Label</label>
        </div>
        <div className='column'>
          <input
            id='home-add-device-modal-device-label-input'
            className='input'
            type='text'
            maxLength={20}
            onChange={handleAddLabel}
            value={addedDeviceLabel}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='home-add-device-modal-device-label-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "#f03a5f",
                textAlign: "left",
                minHeight: "16px",
                visibility: !validateLabel ? "visible" : "hidden",
              }}>
              {validateLabelErrMsg}
            </p>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column flex-start'>
          <label className='label'>Serial Number</label>
        </div>
        <div className='column'>
          <input
            id='home-add-device-modal-device-serial-input'
            className='input'
            type='text'
            maxLength={12}
            onChange={handleAddSN}
            value={addedDeviceSN}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='home-add-device-modal-device-serial-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "#f03a5f",
                textAlign: "left",
                minHeight: "16px",
                visibility: !validateSN ? "visible" : "hidden",
              }}>
              {validateSNErrMsg}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      {!props.isMobile ? (
        <div className='add-wrapper add-wrapper-home'>
          <div className='add-circle-wrapper'>
            <OverlayTrigger
              trigger={["hover", "hover"]}
              key='right'
              placement='top'
              overlay={
                <Popover id='add-popover'>
                  <Popover.Body>
                    Add new Zee / child SM / standalone SM device
                  </Popover.Body>
                </Popover>
              }
              rootClose>
              <span
                id='home-add-device-btn-desktop'
                className='add-circle-content flex-it'
                onClick={() => setIsModal(true)}>
                <img src={plusIcon} alt='add' />
              </span>
            </OverlayTrigger>
          </div>
          <p className={`device-add-success ${props.isSuccess && "in"}`}>
            Device added successfully!
          </p>
          <Modal
            title='Add Device'
            content={JSXContent}
            isModal={isModal}
            handleCloseModal={handleCloseModal}
            actionName='Add'
            actionFn={addNewDevice}
            loading={fetchLoading}
            error={fetchError}
            success={fetchSuccess}
            disabled={!validateLabel || !validateSN}
            errMsg={props.submitErrMsg}
            actionBtnId='home-add-device-modal-action-btn'
            cancelBtnId='home-add-device-modal-cancel-btn'
          />
        </div>
      ) : (
        <div className='add-wrapper add-wrapper-home add-wrapper-mobile'>
          <div className='add-circle-wrapper-mobile'>
            <span
              id='home-add-device-btn-mobile'
              className='add-circle-content flex-it'
              onClick={() => setShowMobileModal(true)}>
              <img src={plusIcon} alt='add' />
            </span>
          </div>
          <p
            className={`device-add-success device-add-success-mobile ${
              props.isSuccess && "in"
            }`}>
            Device added successfully!
          </p>
          <MobileModal
            showMobileModal={showMobileModal}
            setShowMobileModal={setShowMobileModal}
            title='Add Device'
            content={JSXContent}
            handleCloseModal={handleCloseModal}
            actionName='Add'
            actionFn={addNewDevice}
            loading={fetchLoading}
            error={fetchError}
            success={fetchSuccess}
            disabled={!validateLabel || !validateSN}
            errMsg={props.submitErrMsg}
            actionBtnId='home-add-device-modal-action-btn-mobile'
            cancelBtnId='home-add-device-modal-cancel-btn-mobile'
          />
        </div>
      )}
    </Fragment>
  );
};

export default AddDevice;
