import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import Config from "../Config/Config";
import Dashboard from "../Dashboard/Dashboard";
import Modal from "../Modal/Modal";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { validateLabelHelper, validateSNHelper } from "../../utils/helperfuncs";
import { Dropdown, DropdownButton } from "react-bootstrap";
import MobileModal from "../MobileModal/MobileModal";
import { Scrollbars } from "react-custom-scrollbars";
import { KeyboardDatePicker } from "@material-ui/pickers";
import deviceIcon from "../../images/home_device_icon.svg";
import SMIcon from "../../images/home_sm_icon.svg";
import standaloneLANIcon from "../../images/home_smlan_icon.svg";
import standaloneP1Icon from "../../images/home_p1_icon.svg";
import ELWAIcon from "../../images/home_elwa_icon_as_device.svg";
import standaloneELWAIcon from "../../images/home_elwa_icon_as_SM.svg";
import standaloneILMIcon from "../../images/home_ilm_icon.svg";
import editIcon from "../../images/home_edit_icon.svg";
import deleteIcon from "../../images/home_delete_icon.svg";
import addSM from "../../images/home_add_sm_icon.svg";
import mobileEditIcon from "../../images/mobile_home_edit_icon.svg";
import mobileDeleteIcon from "../../images/mobile_home_delete_icon.svg";
import mobileAddSM from "../../images/mobile_home_add_sm_icon.svg";
import settingsIcon from "../../images/home_settings_icon.svg";
import dashboardIcon from "../../images/dashboard_icon.svg";
import remoteIcon from "../../images/zee_remote_icon.svg";
import remoteIconLoading from "../../images/zee_remote_icon_loading2.svg";
import setRemoteOffIcon from "../../images/set_remote_on_icon.svg";
import setRemoteOnIcon from "../../images/set_remote_off_icon.svg";
import setRemoteSetpointIcon from "../../images/set_remote_sp_icon.svg";
import arrowDown from "../../images/arrow_down.svg";
import verticalDotsIcon from "../../images/mobile_vertical_dots.svg";
import baseURL from "../../baseURL";
import "./ListView.css";

const ListView = (props) => {
  const [selectedConfigDeviceData, setSelectedConfigDeviceData] =
    useState(null);
  const [selectedDelZeeDeviceId, setSelectedDelZeeDeviceId] = useState("");
  const [selectedDelDeviceId, setSelectedDelDeviceId] = useState("");
  const [selectedDelDeviceLabel, setSelectedDelDeviceLabel] = useState(null);
  const [selectedDelDeviceType, setSelectedDelDeviceType] = useState("ZEE");
  const [selectedEditZeeDeviceId, setSelectedEditZeeDeviceId] = useState("");
  const [selectedEditDeviceId, setSelectedEditDeviceId] = useState("");
  const [selectedEditDeviceLabel, setSelectedEditDeviceLabel] = useState("");
  const [selectedEditDeviceInitLabel, setSelectedEditDeviceInitLabel] =
    useState("");
  const [selectedEditDeviceType, setSelectedEditDeviceType] = useState("ZEE");
  const [selectedEditDeviceSN, setSelectedEditDeviceSN] = useState("");
  const [selectedEditDeviceInitSN, setSelectedEditDeviceInitSN] = useState("");
  const [editedDeviceParentId, setEditedDeviceParentId] = useState(null);
  const [initEditedDeviceParentId, setInitEditedDeviceParentId] =
    useState(null);
  const [isModalDel, setIsModalDel] = useState(false);
  const [isModalEd, setIsModalEd] = useState(false);
  const [delError, setDelError] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [delSuccess, setDelSuccess] = useState(false);
  const [editError, setEditError] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [transitionStateConfig, setTransitionStateConfig] = useState(true);
  const [transitionStateDash, setTransitionStateDash] = useState(true);
  // Pick selected SmartZee list item
  const [VDId, setVDId] = useState(null);
  const [accOpenClass, setAccOpenClass] = useState("");
  const [accOpenEvtKey, setAccOpenEvtKey] = useState(null);
  // Add new SM
  const [isModalAddSM, setIsModalAddSM] = useState(false);
  const [addedDeviceLabel, setAddedDeviceLabel] = useState("");
  const [addedDeviceSN, setAddedDeviceSN] = useState("");
  const [fetchError, setFetchError] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [selectedZeeDeviceId, setSelectedZeeDeviceId] = useState("");
  const [validateLabel, setValidateLabel] = useState(false);
  const [validateLabelErrMsg, setValidateLabelErrMsg] = useState("");
  const [validateSN, setValidateSN] = useState(false);
  const [validateSNErrMsg, setValidateSNErrMsg] = useState("");
  // Dashboard Type
  const [dashboardType, setDashboardType] = useState("");
  // Mobile Modal
  const [showMobileModalEd, setShowMobileModalEd] = useState(false);
  const [showMobileModalDel, setShowMobileModalDel] = useState(false);
  const [showMobileModalAddSM, setShowMobileModalAddSM] = useState(false);
  // External Days Mobile modal
  const [showMobileModalDays, setShowMobileModalDays] = useState(false);
  const [repeatDaysModal, setRepeatDaysModal] = useState([]);
  const [repeatDaysRecIndexModal, setRepeatDaysRecIndexModal] = useState(null);
  const [isModalSettings, setIsModalSettings] = useState(false);
  const [fetchRetryError, setFetchRetryError] = useState(false);
  const [fetchRetryLoading, setFetchRetryLoading] = useState(false);
  const [fetchRetrySuccess, setFetchRetrySuccess] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [hasChildren, setHasChildren] = useState([]);
  // Chart Range Selector Mobile modal
  const [showMobileModalRS, setShowMobileModalRS] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [startDateEpoch, setStartDateEpoch] = useState(0);
  const [endDate, setEndDate] = useState(null);
  const [endDateEpoch, setEndDateEpoch] = useState(0);
  const [rsMessage, setRsMessage] = useState("");
  const [rangeSelModalFlag, setRangeSelModalFlag] = useState(false);
  const [closeDashboardWS, setCloseDashboardWS] = useState(false);
  // Remote Control Update
  const [isModalRemoteSP, setIsModalRemoteSP] = useState(false);
  const [selectedRemSPDeviceLabel, setselectedRemSPDeviceLabel] = useState("");
  const [remoteSPVal, setRemoteSPVal] = useState("");
  const [remoteResponseCatch, setRemoteResponseCatch] = useState("");
  const [remoteTooltipShow, setRemoteTooltipShow] = useState(false);
  const [remoteResponseAllCatch, setRemoteResponseAllCatch] = useState("");
  const [remoteTooltipAllShow, setRemoteTooltipAllShow] = useState(false);
  const [remoteSPModalError, setRemoteSPModalError] = useState(false);
  const [remoteSPModalLoading, setRemoteSPModalLoading] = useState(false);
  const [remoteSPModalSuccess, setRemoteSPModalSuccess] = useState(false);

  const configRef = useRef();

  const inputRef = useRef();

  const remoteButton = useRef([]);

  const remoteButtonAll = useRef();

  const SPInput = useRef();

  // elgris Products
  const P1Family = ["0x201", "0x211", "0x220"];
  const P1RemoteControl = ["0x220"];
  const ILMFamily = ["0x401", "0x412", "0x421", "0x422"];
  const ILMRemoteControl = ["0x421", "0x422"];
  const SMLAN = ["0x501", "1234"];
  const ZERO = ["0x601"];
  const ELWA = ["0x701"];
  const BASIC = ["0x801"];
  const EMSBattery = ["0x901"];

  // useEffect(() => {
  //   console.log("🚀 ~ useEffect ~ VDId:", VDId);
  //   inputRef.current &&
  //     VDId !== null &&
  //     !isNaN(VDId) &&
  //     props.virtualDeviceList &&
  //     props.virtualDeviceList[0].smartmeters.length !== 0 &&
  //     inputRef.current.click();
  // }, [VDId]);

  useEffect(() => {
    isRetry && configRef.current.getDeviceConfig();
  }, [isRetry]);

  useEffect(() => {
    setVDId(props.virtualDeviceId);
  }, [props.virtualDeviceId]);

  useEffect(() => {
    if (props.remoteControlRes) {
      setRemoteResponseCatch(props.remoteControlRes);
      setRemoteTooltipShow(true);
    } else {
      setRemoteResponseCatch(undefined);
      setRemoteTooltipShow(false);
    }
  }, [props.remoteControlRes]);

  useEffect(() => {
    if (props.remoteControlResAll) {
      console.log(props.remoteControlResAll);
      setRemoteResponseAllCatch(props.remoteControlResAll);
      setRemoteTooltipAllShow(true);
    } else {
      setRemoteResponseAllCatch(undefined);
      setRemoteTooltipAllShow(false);
    }
  }, [props.remoteControlResAll]);

  useEffect(() => {
    console.log(props.onlineSMsArr);
    console.log(props.smetersListDataILM);
    // Online ILMs Array
    console.log(
      props.smetersListDataILM
        .map((value) => {
          if (props.onlineSMsArr.includes(value.id)) {
            return value.id;
          }
        })
        .filter((elm) => elm)
    );
  }, [props.onlineSMsArr, props.smetersListDataILM]);

  useEffect(() => {
    console.log(props.remoteControlIdOrig);
  }, [props.remoteControlIdOrig]);

  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const userId =
    localStorage.getItem("userId") || sessionStorage.getItem("userId");

  const editDevice = async () => {
    setEditLoading(true);
    setEditError(false);
    setEditSuccess(false);
    try {
      let url;

      selectedEditDeviceType === "ZEE"
        ? (url = new URL(
            `./api/v1/users/${
              props.userIdAdminOverride || userId
            }/devices/${selectedEditDeviceId}`,
            baseURL
          ))
        : (url = new URL(
            `./api/v1/devices/${selectedEditZeeDeviceId}/smartmeters/${selectedEditDeviceId}`,
            baseURL
          ));

      const obj = {
        name: selectedEditDeviceLabel,
        serial_number: selectedEditDeviceSN,
      };

      if (selectedEditDeviceType === "SM") {
        obj.device = editedDeviceParentId;
      }

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      let data = await response.json();
      if (response.status === 400) {
        if (
          data.serial_number[0] ===
          `${
            selectedEditDeviceType === "ZEE" ? "device" : "smart meter"
          } with this serial number already exists.`
        ) {
          props.setSubmitErrMsg("Please check submitted serial number");
        }
        setEditLoading(false);
        setEditError(true);
        setTimeout(() => {
          setEditSuccess(false);
          setEditError(false);
          props.setSubmitErrMsg("");
        }, 5000);
      } else {
        setEditLoading(false);
        setEditSuccess(true);
        setSelectedEditDeviceLabel("");
        setSelectedEditDeviceSN("");
        handleCloseModalEd();
        props.getDeviceList();
        selectedEditDeviceType === "SM" &&
          props.getSMetersList(selectedEditZeeDeviceId);
        setTimeout(() => {
          setEditSuccess(false);
          setEditError(false);
        }, 5000);
      }
    } catch (error) {
      setEditLoading(false);
      setEditError(true);
      setTimeout(() => {
        setEditError(false);
      }, 3000);
    }
  };

  const deleteDevice = async () => {
    setDelLoading(true);
    setDelError(false);
    setDelSuccess(false);
    try {
      const head = new Headers();
      head.append("Authorization", "Bearer " + token);
      let url;

      selectedDelDeviceType === "ZEE"
        ? (url = new URL(`./api/v1/devices/${selectedDelDeviceId}`, baseURL))
        : (url = new URL(
            `./api/v1/devices/${selectedDelZeeDeviceId}/smartmeters/${selectedDelDeviceId}`,
            baseURL
          ));

      await fetch(url, {
        method: "DELETE",
        headers: head,
      });
      setDelLoading(false);
      setDelSuccess(true);
      handleCloseModalDel();
      props.getDeviceList();
      selectedDelDeviceType === "SM" &&
        props.getSMetersList(selectedDelZeeDeviceId);
      setTimeout(() => {
        setDelSuccess(false);
        setDelError(false);
      }, 5000);
    } catch (error) {
      setDelLoading(false);
      setDelError(true);
      setTimeout(() => {
        setDelError(false);
      }, 3000);
    }
  };

  const addSMDevice = async () => {
    setFetchLoading(true);
    setFetchError(false);
    setFetchSuccess(false);
    try {
      const url = new URL(
        `./api/v1/devices/${selectedZeeDeviceId}/smartmeters`,
        baseURL
      );

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device: selectedZeeDeviceId,
          name: addedDeviceLabel,
          serial_number: addedDeviceSN,
        }),
      });

      let data = await response.json();

      if (response.status !== 201) {
        if (
          data.serial_number[0] ===
          "smart meter with this serial number already exists."
        ) {
          props.setSubmitErrMsg("Please check submitted serial number");
        }
        setFetchLoading(false);
        setFetchError(true);
        setTimeout(() => {
          setFetchSuccess(false);
          setFetchError(false);
          props.setSubmitErrMsg("");
        }, 5000);
      } else {
        setFetchLoading(false);
        setFetchSuccess(true);
        props.isMobile
          ? setTimeout(() => {
              props.setIsSuccess(true);
            }, 500)
          : props.setIsSuccess(true);
        setAddedDeviceLabel("");
        setAddedDeviceSN("");
        handleCloseModalAddSM();
        setTimeout(() => {
          setFetchSuccess(false);
          setFetchError(false);
          props.setIsSuccess(false);
        }, 5000);
        // Add newly added SM data to state via frontend
        props.setSmetersListData((prev) => [...prev, data]);
        props.setDeviceListData((prev) =>
          prev.map((obj) =>
            obj.id === selectedZeeDeviceId
              ? { ...obj, smartmeters: [...obj.smartmeters, data.id] }
              : obj
          )
        );
        // props.getDeviceList();
        // props.getSMetersList(selectedZeeDeviceId);
      }
    } catch (error) {
      setFetchLoading(false);
      setFetchError(true);
      setTimeout(() => {
        setFetchError(false);
      }, 3000);
    }
  };

  const handleConfigClick = (e, obj) => {
    props.setIsConfig(true);
    setSelectedConfigDeviceData(obj);
    setTransitionStateConfig((transitionStateConfig) => !transitionStateConfig);
    props.setCloseDeviceWS(true);
    setCloseDashboardWS(true);
  };

  const handleConfigBack = (e) => {
    props.setIsConfig(false);
    setTransitionStateConfig((transitionStateConfig) => !transitionStateConfig);
    setTimeout(() => {
      props.setAnimExit(true);
    }, 1000);
    setAccOpenClass("");
    setAccOpenEvtKey(null);
    props.setOpenZeeId(null);
    props.setCloseDeviceWS(false);
    setCloseDashboardWS(true);
    // Reset virtual device ID to let the expand standalone SMs useEffect kick in
    setVDId(null);
    setTimeout(() => {
      setVDId(props.virtualDeviceId);
    }, 200);
    // Get device list as some user might have deleted some device or SM
    props.getDeviceList();
  };

  const handleDashboardClick = (e, obj) => {
    props.setIsDashboard(true);
    setSelectedConfigDeviceData(obj);
    setTransitionStateDash((transitionStateDash) => !transitionStateDash);
    props.setCloseDeviceWS(true);
    setCloseDashboardWS(false);
  };

  const handleDashboardBack = (e) => {
    props.setIsDashboard(false);
    props.setDashboardType("ZEE");
    setTransitionStateDash((transitionStateDash) => !transitionStateDash);
    setTimeout(() => {
      props.setAnimExit(true);
    }, 1000);
    setAccOpenClass("");
    setAccOpenEvtKey(null);
    props.setOpenZeeId(null);
    setStartDate(null);
    setEndDate(null);
    setStartDateEpoch(0);
    setEndDateEpoch(0);
    props.setCloseDeviceWS(false);
    setCloseDashboardWS(true);
    // Reset virtual device ID to let the expand standalone SMs useEffect kick in
    setVDId(null);
    setTimeout(() => {
      setVDId(props.virtualDeviceId);
    }, 200);
    // Get device list as some user might have deleted some device or SM
    props.getDeviceList();
  };

  const handleCloseModalDel = () => {
    !props.isMobile ? setIsModalDel(!isModalDel) : setShowMobileModalDel(false);
    // Clear button to defaults
    setDelLoading(false);
    setDelSuccess(false);
    setDelError(false);
    props.setSubmitErrMsg("");
  };

  const handleCloseModalEd = () => {
    !props.isMobile ? setIsModalEd(!isModalEd) : setShowMobileModalEd(false);
    setValidateLabel(false);
    setValidateSN(false);
    setValidateLabelErrMsg("");
    setValidateSNErrMsg("");
    setSelectedEditDeviceLabel("");
    setSelectedEditDeviceSN("");
    // Clear button to defaults
    setEditLoading(false);
    setEditSuccess(false);
    setEditError(false);
    props.setSubmitErrMsg("");
  };

  const handleCloseModalAddSM = () => {
    !props.isMobile
      ? setIsModalAddSM(!isModalAddSM)
      : setShowMobileModalAddSM(false);
    setValidateLabel(false);
    setValidateSN(false);
    setValidateLabelErrMsg("");
    setValidateSNErrMsg("");
    setAddedDeviceLabel("");
    setAddedDeviceSN("");
    // Clear button to defaults
    setFetchLoading(false);
    setFetchSuccess(false);
    setFetchError(false);
    props.setSubmitErrMsg("");
  };

  const handleEditLabel = (e) => {
    setSelectedEditDeviceLabel(e.target.value);
    validateLabelHelper(
      e.target.value,
      setValidateLabel,
      setValidateLabelErrMsg
    );
    if (selectedEditDeviceInitLabel === e.target.value) {
      setValidateLabel(false);
      setValidateLabelErrMsg("Label is not changed!");
    }
  };

  const handleEditSN = (e) => {
    setSelectedEditDeviceSN(e.target.value);
    validateSNHelper(e.target.value, setValidateSN, setValidateSNErrMsg);
    if (selectedEditDeviceInitSN === e.target.value) {
      setValidateSN(false);
      setValidateSNErrMsg("Serial number is not changed!");
    }
  };

  const handleAddLabel = (e) => {
    setAddedDeviceLabel(e.target.value);
    validateLabelHelper(
      e.target.value,
      setValidateLabel,
      setValidateLabelErrMsg
    );
  };

  const handleAddSN = (e) => {
    setAddedDeviceSN(e.target.value);
    validateSNHelper(e.target.value, setValidateSN, setValidateSNErrMsg);
  };

  const hasChildrenFunc = (deviceId) => {
    const output = props.deviceListData
      .filter((obj) => obj.id === deviceId)
      .map((obj) => obj.smartmeters);
    setHasChildren(output[0]);
  };

  const renderedMeters = (id, type) =>
    props.smetersListData
      .filter((obj) => {
        switch (type) {
          case "ALL":
            return obj.device === id;
          case "ILM":
            return obj.device === id && ILMFamily.includes(obj.sm_type);
          case "LAN":
            return obj.device === id && SMLAN.includes(obj.sm_type);
          case "P1":
            return obj.device === id && P1Family.includes(obj.sm_type);
          default:
            return obj;
        }
      })
      .map((obj, index) => {
        return (
          <li key={obj.id} className='list-group-item-meter'>
            <span className='line-border'></span>
            <span className='device-list-title'>
              <span
                className={
                  index >= 9 && "meter-list-number-wrapper-double-digit"
                }>
                <span className='device-list-number'>
                  <span
                    id='home-sm-device-number'
                    className={index >= 9 && "double-digit"}>
                    {index + 1}
                  </span>
                  <img
                    className='device-icon-img'
                    src={SMIcon}
                    alt='device icon'
                  />
                </span>
              </span>
              <span
                id='home-sm-device-title'
                className='device-list-title-meter'>
                {obj.name}
              </span>
            </span>
            <div className='flex-start'>
              <span
                id='home-sm-device-status-circle'
                className='status-circle'
                style={{
                  backgroundColor: props.onlineSMsArr.some(
                    (arrId) => arrId === obj.id
                  )
                    ? "#5BEB97"
                    : "#84978C",
                }}></span>
              <span
                id='home-sm-device-status-text'
                className='status-text'
                style={{ marginLeft: "10px", fontSize: "14px" }}>
                {props.onlineSMsArr.some((arrId) => arrId === obj.id)
                  ? "ONLINE"
                  : "OFFLINE"}
              </span>
            </div>
            {!props.isMobile && (
              <div className='device-controls'>
                <img
                  id='home-sm-device-del-btn'
                  src={deleteIcon}
                  alt='delete device'
                  onClick={() => {
                    setIsModalDel(true);
                    setSelectedDelZeeDeviceId(obj.device);
                    setSelectedDelDeviceId(obj.id);
                    setSelectedDelDeviceLabel(obj.name);
                    setSelectedDelDeviceType("SM");
                  }}
                />
                <img
                  id='home-sm-device-edit-btn'
                  src={editIcon}
                  alt='edit device'
                  onClick={() => {
                    setIsModalEd(true);
                    setSelectedEditZeeDeviceId(obj.device);
                    setEditedDeviceParentId(obj.device);
                    setInitEditedDeviceParentId(obj.device);
                    setSelectedEditDeviceId(obj.id);
                    setSelectedEditDeviceLabel(obj.name);
                    setSelectedEditDeviceInitLabel(obj.name);
                    setSelectedEditDeviceSN(obj.serial_number);
                    setSelectedEditDeviceInitSN(obj.serial_number);
                    setSelectedEditDeviceType("SM");
                  }}
                />
              </div>
            )}
            <div className='action-btns'>
              <button
                id='home-sm-device-dash-btn'
                className='btn-small btn-home flex-it'>
                <img
                  src={dashboardIcon}
                  alt='sm dashboard'
                  onClick={(e) => {
                    handleDashboardClick(e, obj);
                    props.setDashboardType("SM");
                    setDashboardType("SM");
                  }}
                />
              </button>
              <DropdownButton
                title={
                  props.remoteIsLoading &&
                  props.remoteControlIdOrig &&
                  props.remoteIsLoadingMode !== "all" &&
                  props.remoteControlIdOrig[0] === obj.id ? (
                    <img
                      src={remoteIconLoading}
                      alt='remote icon loading'
                      style={{
                        width: "30px",
                        display: "block",
                        transform: "scale(-1, 1)",
                      }}
                    />
                  ) : !props.remoteIsLoading &&
                    props.remoteControlRes &&
                    props.remoteControlIdOrig &&
                    props.remoteIsLoadingMode !== "all" &&
                    props.remoteControlIdOrig[0] === obj.id ? (
                    <span style={{ textTransform: "capitalize" }}>
                      {props.remoteControlRes}
                    </span>
                  ) : (
                    "Set Remote"
                  )
                }
                className={`btn-small btn-home btn-remote-control flex-it three-dots dots-button remote-control-dropdown-btn sub-btn ${
                  props.remoteIsLoading &&
                  props.remoteControlIdOrig &&
                  props.remoteControlIdOrig[0] === obj.id
                    ? "cursor-nallowed"
                    : ""
                }`}
                style={{
                  display:
                    P1RemoteControl.includes(obj.sm_type) ||
                    ILMRemoteControl.includes(obj.sm_type)
                      ? "flex"
                      : "none",
                }}
                // Disable set remote button for OFFLINE ILMs that support remote
                disabled={
                  !props.isDemo &&
                  !props.smetersListDataILM
                    .map((value) => {
                      if (
                        props.onlineSMsArr.includes(value.id) &&
                        ILMRemoteControl.includes(value.sm_type)
                      ) {
                        return value.id;
                      }
                    })
                    .filter((elm) => elm)
                    .includes(obj.id)
                }
                // End
              >
                <Dropdown.Item
                  onClick={() => {
                    props.setRemoteControlId([obj.id]);
                    props.setRemoteControlIdOrig([obj.id]);
                    props.setRemoteControlVal("1");
                  }}>
                  <img src={setRemoteOnIcon} alt='Set remote ON icon' />
                  <span style={{ paddingLeft: "10px" }}>Set Remote ON</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    props.setRemoteControlId([obj.id]);
                    props.setRemoteControlIdOrig([obj.id]);
                    props.setRemoteControlVal("0");
                  }}>
                  <img src={setRemoteOffIcon} alt='Set remote OFF icon' />
                  <span style={{ paddingLeft: "10px" }}>Set Remote OFF</span>
                </Dropdown.Item>
                {P1RemoteControl.includes(obj.sm_type) && (
                  <Dropdown.Item
                    onClick={() => {
                      setIsModalRemoteSP(true);
                      setTimeout(() => {
                        SPInput.current.focus();
                      }, 50);
                      setselectedRemSPDeviceLabel(obj.name);
                    }}>
                    <img
                      src={setRemoteSetpointIcon}
                      alt='Set remote Setpoint icon'
                    />
                    <span style={{ paddingLeft: "10px" }}>
                      Set Remote Setpoint
                    </span>
                  </Dropdown.Item>
                )}
              </DropdownButton>
            </div>
            {props.isMobile && (
              <div className='device-controls-mobile'>
                <DropdownButton
                  id='home-sm-device-controls-dot-mobile'
                  title={<img src={verticalDotsIcon} alt='dots' />}
                  className='three-dots dots-button'>
                  <Dropdown.Item
                    id='home-sm-device-edit-device-btn-mobile'
                    onClick={() => {
                      setShowMobileModalEd(true);
                      setSelectedEditZeeDeviceId(obj.device);
                      setEditedDeviceParentId(obj.device);
                      setInitEditedDeviceParentId(obj.device);
                      setSelectedEditDeviceId(obj.id);
                      setSelectedEditDeviceLabel(obj.name);
                      setSelectedEditDeviceInitLabel(obj.name);
                      setSelectedEditDeviceSN(obj.serial_number);
                      setSelectedEditDeviceInitSN(obj.serial_number);
                      setSelectedEditDeviceType("SM");
                    }}>
                    <img src={mobileEditIcon} alt='edit device' />
                    <span style={{ paddingLeft: "10px" }}>Edit Device</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    id='home-sm-device-del-device-btn-mobile'
                    onClick={() => {
                      setShowMobileModalDel(true);
                      setSelectedDelZeeDeviceId(obj.device);
                      setSelectedDelDeviceId(obj.id);
                      setSelectedDelDeviceLabel(obj.name);
                      setSelectedDelDeviceType("SM");
                    }}>
                    <img src={mobileDeleteIcon} alt='delete device' />
                    <span style={{ paddingLeft: "10px" }}>Delete Device</span>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            )}
          </li>
        );
      });

  const CustomToggle = ({ children, eventKey, callback, id, obj }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      callback && callback(eventKey);
      if (eventKey === accOpenEvtKey) {
        setAccOpenClass("fwd");
        setTimeout(() => {
          setAccOpenClass("fwd-fin");
          setAccOpenEvtKey(null);
          props.setOpenZeeId(null);
        }, 500);
      } else {
        setAccOpenEvtKey(eventKey);
        props.setOpenZeeId(eventKey);
        setAccOpenClass("rev");
        props.setSmetersListData([]);
        setTimeout(() => {
          setAccOpenClass("rev-fin");
          props.getSMetersList(id);
        }, 500);
      }
    });

    return (
      <button
        ref={inputRef}
        type='button'
        id='home-acc-dropdown-btn'
        className={`acc-dropdown-btn ${
          obj.smartmeters.length === 0 && "no-children"
        }`}
        onClick={decoratedOnClick}>
        {children}
      </button>
    );
  };

  const renderedList =
    props.deviceListData &&
    props.deviceListData.map((obj, index) => {
      return (
        <Card key={obj.id}>
          <Card.Header className='list-group-item'>
            <span
              className={`device-list-number-wrapper ${
                index >= 9 && "device-list-number-wrapper-double-digit"
              }`}>
              <span className='device-list-number'>
                <span
                  id='home-zee-device-number'
                  className={index >= 9 && "double-digit"}>
                  {index + 1}
                </span>
                <img
                  className='device-icon-img'
                  src={deviceIcon}
                  alt='device icon'
                />
              </span>
            </span>
            <span
              id='home-zee-device-title'
              className='device-list-title device-list-title-zee'>
              {obj.name}
            </span>
            <div className='device-status flex-start'>
              <span
                id='home-zee-device-status-circle'
                className='status-circle'
                style={{
                  backgroundColor: props.onlineDevicesArr.some(
                    (arrId) => arrId === obj.id
                  )
                    ? "#5BEB97"
                    : "#84978C",
                }}></span>
              <span
                id='home-zee-device-status-text'
                className='status-text'
                style={{ marginLeft: "10px", fontSize: "14px" }}>
                {props.onlineDevicesArr.some((arrId) => arrId === obj.id)
                  ? "ONLINE"
                  : "OFFLINE"}
              </span>
            </div>
            {!props.isMobile && (
              <div className='device-controls'>
                <img
                  id='home-zee-device-del-btn'
                  src={deleteIcon}
                  alt='delete device'
                  onClick={() => {
                    setIsModalDel(true);
                    setSelectedDelDeviceId(obj.id);
                    setSelectedDelDeviceLabel(obj.name);
                    setSelectedDelDeviceType("ZEE");
                    hasChildrenFunc(obj.id);
                  }}
                />
                <img
                  id='home-zee-device-edit-btn'
                  src={editIcon}
                  alt='edit device'
                  onClick={() => {
                    setIsModalEd(true);
                    setSelectedEditDeviceId(obj.id);
                    setSelectedEditDeviceLabel(obj.name);
                    setSelectedEditDeviceInitLabel(obj.name);
                    setSelectedEditDeviceSN(obj.serial_number);
                    setSelectedEditDeviceInitSN(obj.serial_number);
                    setSelectedEditDeviceType("ZEE");
                  }}
                />
                <img
                  id='home-zee-device-add-sm-btn'
                  src={addSM}
                  alt='add new sm'
                  onClick={() => {
                    setIsModalAddSM(true);
                    setSelectedZeeDeviceId(obj.id);
                  }}
                />
              </div>
            )}
            <div className='action-btns'>
              <button
                id='home-zee-device-settings-btn'
                className='btn-small btn-home flex-it'>
                <img
                  src={settingsIcon}
                  alt='device settings'
                  onClick={(e) => handleConfigClick(e, obj)}
                />
              </button>
              <button
                id='home-zee-device-dash-btn'
                className='btn-small btn-home flex-it'>
                <img
                  src={dashboardIcon}
                  alt='smartzee dashboard'
                  onClick={(e) => {
                    handleDashboardClick(e, obj);
                    props.setDashboardType("ZEE");
                    setDashboardType("ZEE");
                  }}
                />
              </button>
              <DropdownButton
                title={
                  <>
                    <img
                      src={
                        props.remoteIsLoading &&
                        props.remoteControlIdOrig &&
                        props.remoteControlIdOrig[0] === obj.id
                          ? remoteIconLoading
                          : remoteIcon
                      }
                      style={{
                        display: "block",
                        transform: "scale(-1, 1)",
                      }}
                      alt='device remote control'
                      ref={(ref) => (remoteButton.current[index] = ref)}
                      id={`remote-control-dropdown-btn-icon${index}`}
                    />
                    <Overlay
                      target={remoteButton.current[index]}
                      show={
                        props.remoteControlRes &&
                        props.remoteControlIdOrig &&
                        props.remoteControlIdOrig[0] === obj.id &&
                        remoteTooltipShow
                      }
                      placement='top'>
                      {(props) => (
                        <Tooltip id='device-remote-response-tooltip' {...props}>
                          {remoteResponseCatch}
                        </Tooltip>
                      )}
                    </Overlay>
                  </>
                }
                className={`btn-small btn-home flex-it three-dots dots-button sub-btn remote-control-dropdown-btn ${
                  props.remoteIsLoading &&
                  props.remoteControlIdOrig &&
                  props.remoteControlIdOrig[0] === obj.id
                    ? "cursor-nallowed"
                    : ""
                }`}
                // Disable set remote button for OFFLINE Zee devices
                disabled={
                  !props.isDemo &&
                  !props.onlineDevicesArr.some((arrId) => arrId === obj.id)
                }
                // End
              >
                <Dropdown.Item
                  onClick={() => {
                    props.setRemoteControlId([obj.id]);
                    props.setRemoteControlIdOrig([obj.id]);
                    props.setRemoteControlVal("1");
                  }}>
                  <img src={setRemoteOnIcon} alt='Set remote ON icon' />
                  <span style={{ paddingLeft: "10px" }}>Set Remote ON</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    props.setRemoteControlId([obj.id]);
                    props.setRemoteControlIdOrig([obj.id]);
                    props.setRemoteControlVal("0");
                  }}>
                  <img src={setRemoteOffIcon} alt='Set remote OFF icon' />
                  <span style={{ paddingLeft: "10px" }}>Set Remote OFF</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setIsModalRemoteSP(true);
                    setTimeout(() => {
                      SPInput.current.focus();
                    }, 50);
                    setselectedRemSPDeviceLabel(obj.name);
                  }}>
                  <img
                    src={setRemoteSetpointIcon}
                    alt='Set remote Setpoint icon'
                  />
                  <span style={{ paddingLeft: "10px" }}>
                    Set Remote Setpoint
                  </span>
                </Dropdown.Item>
              </DropdownButton>
            </div>
            {props.isMobile && (
              <div className='device-controls-mobile'>
                <DropdownButton
                  id='home-zee-device-controls-dot-mobile'
                  title={<img src={verticalDotsIcon} alt='dots' />}
                  className='three-dots dots-button'>
                  <Dropdown.Item
                    id='home-zee-device-add-sm-btn-mobile'
                    onClick={() => {
                      setShowMobileModalAddSM(true);
                      setSelectedZeeDeviceId(obj.id);
                    }}>
                    <img src={mobileAddSM} alt='add new sm' />
                    <span style={{ paddingLeft: "10px" }}>Add SM Device</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    id='home-zee-device-edit-device-btn-mobile'
                    onClick={() => {
                      setShowMobileModalEd(true);
                      setSelectedEditDeviceId(obj.id);
                      setSelectedEditDeviceLabel(obj.name);
                      setSelectedEditDeviceInitLabel(obj.name);
                      setSelectedEditDeviceSN(obj.serial_number);
                      setSelectedEditDeviceInitSN(obj.serial_number);
                      setSelectedEditDeviceType("ZEE");
                    }}>
                    <img src={mobileEditIcon} alt='edit device' />
                    <span style={{ paddingLeft: "10px" }}>Edit Device</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    id='home-zee-device-del-device-btn-mobile'
                    onClick={() => {
                      setShowMobileModalDel(true);
                      setSelectedDelDeviceId(obj.id);
                      setSelectedDelDeviceLabel(obj.name);
                      setSelectedDelDeviceType("ZEE");
                      hasChildrenFunc(obj.id);
                    }}>
                    <img src={mobileDeleteIcon} alt='delete device' />
                    <span style={{ paddingLeft: "10px" }}>Delete Device</span>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            )}
            <CustomToggle eventKey={obj.id} id={obj.id} obj={obj}>
              <img
                className={accOpenEvtKey == obj.id && accOpenClass}
                src={arrowDown}
                alt='arrow'
              />
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={obj.id}>
            <ul
              className='meters-list'
              style={{
                display: obj.smartmeters.length > 0 ? "block" : "none",
              }}>
              {props.SMLoadedId === null &&
              props.SMLoading &&
              props.SMLoadedId !== obj.id ? (
                <li className='list-group-item-meter'>
                  <span className='line-border'></span>
                  <div className='lds-ring'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </li>
              ) : props.SMLoadingErr ? (
                <li className='list-group-item-meter list-group-item-err'>
                  <span className='line-border'></span>
                  <p style={{ fontSize: "12px", textAlign: "left" }}>
                    Failed to get SM list. Please close drawer and reopen it to
                    try again.
                  </p>
                </li>
              ) : (
                renderedMeters(obj.id, "ALL")
              )}
            </ul>
          </Accordion.Collapse>
        </Card>
      );
    });

  const renderedVirtList = (type) =>
    props.virtualDeviceList &&
    props.virtualDeviceList.map((obj, index) => {
      return (
        <Card key={obj.id}>
          <Card.Header className='list-group-item list-group-item-virt'>
            <span
              className={`device-list-number-wrapper ${
                index >= 9 && "device-list-number-wrapper-double-digit"
              }`}>
              <span className='device-list-number'>
                <img
                  className='device-icon-img virt-device-icon-img'
                  src={
                    type === "P1"
                      ? standaloneP1Icon
                      : type === "ILM"
                      ? standaloneILMIcon
                      : standaloneLANIcon
                  }
                  alt='virtual device icon'
                />
              </span>
            </span>
            <span className='device-list-title device-list-title-zee device-list-title-standalone'>
              {type} Smart Meters
            </span>
            <div className='device-status flex-start'></div>
            {!props.isMobile && <div className='device-controls'></div>}
            <div className='action-btns'>
              {type === "ILM" && (
                <DropdownButton
                  ref={remoteButtonAll}
                  title={
                    <>
                      {props.remoteIsLoading &&
                      props.remoteIsLoadingMode === "all" ? (
                        <img
                          src={remoteIconLoading}
                          alt='remote icon loading'
                          style={{
                            width: "30px",
                            display: "block",
                            transform: "scale(-1, 1)",
                          }}
                        />
                      ) : (
                        "Set Remote All"
                      )}
                      <Overlay
                        target={remoteButtonAll.current}
                        show={
                          props.remoteControlResAll &&
                          props.remoteControlResAll.length !== 0 &&
                          props.remoteIsLoadingMode === "all" &&
                          remoteTooltipAllShow
                        }
                        placement='top'>
                        {(props) => (
                          <Tooltip
                            id='device-remote-response-tooltip'
                            {...props}>
                            {remoteResponseAllCatch.map((obj, index) => (
                              <div key={index} style={{ textAlign: "left" }}>
                                {obj}
                              </div>
                            ))}
                          </Tooltip>
                        )}
                      </Overlay>
                    </>
                  }
                  className={`btn-small btn-home btn-remote-control flex-it three-dots dots-button sub-btn remote-control-dropdown-btn remote-control-all-btn ${
                    props.remoteIsLoading && props.remoteIsLoadingMode === "all"
                      ? "cursor-nallowed"
                      : ""
                  }`}
                  // Disable set remote all button if all ILM smart meters are OFFLINE
                  disabled={
                    // Check if NONE of ILM SM type that supports remote control is online
                    (!props.isDemo &&
                      !props.smetersListDataILM &&
                      props.smetersListDataILM.length === 0) ||
                    !props.smetersListDataILM.some((obj) =>
                      props.smetersListDataILM
                        .map((value) => {
                          if (
                            props.onlineSMsArr.includes(value.id) &&
                            ILMRemoteControl.includes(value.sm_type)
                          ) {
                            return value.id;
                          }
                        })
                        .filter((elm) => elm)
                        .includes(obj.id)
                    )
                  }
                  // End
                >
                  <Dropdown.Item
                    onClick={() => {
                      props.setRemoteControlId(
                        props.smetersListDataILM
                          .map((value) => {
                            if (
                              props.onlineSMsArr.includes(value.id) &&
                              ILMRemoteControl.includes(value.sm_type)
                            ) {
                              return value.id;
                            }
                          })
                          .filter((elm) => elm)
                      );
                      props.setRemoteControlIdOrig(
                        props.smetersListDataILM
                          .map((value) => {
                            if (
                              props.onlineSMsArr.includes(value.id) &&
                              ILMRemoteControl.includes(value.sm_type)
                            ) {
                              return value.id;
                            }
                          })
                          .filter((elm) => elm)
                      );
                      props.setRemoteControlVal("1");
                      props.setRemoteIsLoadingMode("all");
                    }}>
                    <img src={setRemoteOnIcon} alt='Set remote All ON icon' />
                    <span style={{ paddingLeft: "10px" }}>
                      Set Remote All ON
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      props.setRemoteControlId(
                        props.smetersListDataILM
                          .map((value) => {
                            if (
                              props.onlineSMsArr.includes(value.id) &&
                              ILMRemoteControl.includes(value.sm_type)
                            ) {
                              return value.id;
                            }
                          })
                          .filter((elm) => elm)
                      );
                      props.setRemoteControlIdOrig(
                        props.smetersListDataILM
                          .map((value) => {
                            if (
                              props.onlineSMsArr.includes(value.id) &&
                              ILMRemoteControl.includes(value.sm_type)
                            ) {
                              return value.id;
                            }
                          })
                          .filter((elm) => elm)
                      );
                      props.setRemoteControlVal("0");
                      props.setRemoteIsLoadingMode("all");
                    }}>
                    <img src={setRemoteOffIcon} alt='Set remote All OFF icon' />
                    <span style={{ paddingLeft: "10px" }}>
                      Set Remote All OFF
                    </span>
                  </Dropdown.Item>
                </DropdownButton>
              )}
            </div>
            <CustomToggle eventKey={type + VDId} id={obj.id} obj={obj}>
              <img
                className={accOpenEvtKey == type + VDId && accOpenClass}
                src={arrowDown}
                alt='arrow'
              />
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={type + VDId}>
            <ul
              className='meters-list'
              style={{
                display: obj.smartmeters.length > 0 ? "block" : "none",
              }}>
              {props.SMLoadedId === null &&
              props.SMLoading &&
              props.SMLoadedId !== obj.id ? (
                <li className='list-group-item-meter'>
                  <span className='line-border'></span>
                  <div className='lds-ring'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </li>
              ) : props.SMLoadingErr ? (
                <li className='list-group-item-meter list-group-item-err'>
                  <span className='line-border'></span>
                  <p style={{ fontSize: "12px", textAlign: "left" }}>
                    Failed to get SM list. Please close drawer and reopen it to
                    try again.
                  </p>
                </li>
              ) : !props.SMLoading &&
                props.SMLoadedId !== null &&
                props.SMLoadedId === obj.id &&
                ((type === "ILM" && props.smetersListDataILM.length === 0) ||
                  (type === "P1" && props.smetersListDataP1.length === 0) ||
                  (type === "LAN" && props.smetersListDataLAN.length === 0)) ? (
                <li className='list-group-item-meter list-group-item-err'>
                  <span className='line-border'></span>
                  <p style={{ fontSize: "12px", textAlign: "left" }}>
                    No smart meters
                  </p>
                </li>
              ) : (
                renderedMeters(obj.id, type)
              )}
            </ul>
          </Accordion.Collapse>
        </Card>
      );
    });

  const JSXContentDel = (
    <Fragment>
      <h6 style={{ textAlign: "left" }}>
        Are you sure you want to delete {selectedDelDeviceLabel}?
      </h6>
      <h6
        id='home-del-device-modal-message'
        style={{
          textAlign: "left",
          marginTop: "1rem",
          color: "red",
          fontWeight: "bold",
          // display:
          //   (selectedDelDeviceType === "SM" || hasChildren.length === 0) &&
          //   "none",
        }}>
        {selectedDelDeviceType === "SM"
          ? "CAUTION!: All smart meter device data will be destroyed forever as well!"
          : selectedDelDeviceType === "ZEE" && hasChildren.length === 0
          ? "CAUTION!: All SmartZee device data will be destroyed forever as well!"
          : "CAUTION!: All smart meters defined under this SmartZee device will be deleted as well! All SmartZee and child smart meters data will be destroyed forever as well!!"}
      </h6>
    </Fragment>
  );

  const JSXContentEd = (
    <Fragment>
      {selectedEditDeviceType === "SM" && (
        <div className='columns '>
          <div className='column flex-start'>
            <label className='label'>Device Parent</label>
          </div>
          <div className='column'>
            <div className='select is-fullwidth'>
              <select
                id='home-edit-device-modal-device-parent-sel'
                className='input load-control-input transparent-input'
                value={editedDeviceParentId}
                onInput={(e) =>
                  setEditedDeviceParentId(parseInt(e.target.value))
                }>
                {props.deviceListData.map((obj, key) => {
                  return (
                    <option key={key} value={obj.id}>
                      {obj.name}
                    </option>
                  );
                })}
                <option value={props.virtualDeviceId}>None</option>
              </select>
            </div>
          </div>
          <div className='column'></div>
        </div>
      )}
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>Device Label</label>
        </div>
        <div className='column'>
          <input
            id='home-edit-device-modal-device-label-input'
            className='input'
            type='text'
            maxLength={20}
            onChange={handleEditLabel}
            value={selectedEditDeviceLabel}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='home-edit-device-modal-device-label-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "red",
                textAlign: "left",
                visibility: !validateLabel ? "visible" : "hidden",
              }}>
              {validateLabelErrMsg}
            </p>
          </div>
        </div>
      </div>
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>Serial Number</label>
        </div>
        <div className='column'>
          <input
            id='home-edit-device-modal-device-serial-input'
            className='input'
            type='text'
            maxLength={12}
            onChange={handleEditSN}
            value={selectedEditDeviceSN}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='home-edit-device-modal-device-serial-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "red",
                textAlign: "left",
                visibility: !validateSN ? "visible" : "hidden",
              }}>
              {validateSNErrMsg}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const JSXContentAddSM = (
    <Fragment>
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>Device Label</label>
        </div>
        <div className='column'>
          <input
            id='home-add-sm-device-modal-device-label-input'
            className='input'
            type='text'
            maxLength={20}
            onChange={handleAddLabel}
            value={addedDeviceLabel}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='home-add-sm-device-modal-device-label-input-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "red",
                textAlign: "left",
                visibility: !validateLabel ? "visible" : "hidden",
              }}>
              {validateLabelErrMsg}
            </p>
          </div>
        </div>
      </div>
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>Serial Number</label>
        </div>
        <div className='column'>
          <input
            id='home-add-sm-device-modal-device-serial-input'
            className='input'
            type='text'
            maxLength={12}
            onChange={handleAddSN}
            value={addedDeviceSN}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='home-add-sm-device-modal-device-serial-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "red",
                textAlign: "left",
                visibility: !validateSN ? "visible" : "hidden",
              }}>
              {validateSNErrMsg}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );

  // External Days Mobile modal
  let daysArr = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  // External Days Mobile modal
  const handleSingleCheck = (e) => {
    let name = e.target.name;
    let newArr;
    if (!repeatDaysModal.includes(name)) {
      newArr = [...repeatDaysModal];
      newArr.push(name);
      setRepeatDaysModal(newArr);
    } else {
      newArr = [...repeatDaysModal];
      newArr.splice(repeatDaysModal.indexOf(name), 1);
      setRepeatDaysModal(newArr);
    }
    console.log(newArr);
  };

  // External Days Mobile modal
  const handleCloseModalDays = () => {
    setRepeatDaysModal([]);
    setShowMobileModalDays(false);
  };

  // External Days Mobile modal
  const handleSaveDays = () => {
    setShowMobileModalDays(false);
  };

  // External Days Mobile modal
  const returnRealDayNames = (day) => {
    switch (day) {
      case "Mon":
        return "Monday";
      case "Tue":
        return "Tuesday";
      case "Wed":
        return "Wednesday";
      case "Thu":
        return "Thursday";
      case "Fri":
        return "Friday";
      case "Sat":
        return "Saturday";
      case "Sun":
        return "Sunday";
    }
  };

  // External Days Mobile modal
  const JSXContentRepeatDays = (
    <Fragment>
      <div className='time-days-mobile'>
        {daysArr.map((day, index) => (
          <div key={index} className='time-day-mobile'>
            <span>{returnRealDayNames(day)}</span>
            <input
              id={`config-time-repeat-days-modal-mobile-${day}`}
              className='checkbox'
              type='checkbox'
              name={day}
              checked={repeatDaysModal.includes(day)}
              onChange={handleSingleCheck}
              disabled={false}
            />
          </div>
        ))}
      </div>
    </Fragment>
  );

  // Chart Range Selector Mobile modal
  const convertDateToEpoch = (date) => {
    return parseInt(date / 1000);
  };

  const handleChangeStartDate = (val) => {
    setStartDate(val);
    setStartDateEpoch(convertDateToEpoch(val));
  };

  const handleChangeEndDate = (val) => {
    setEndDate(val);
    setEndDateEpoch(convertDateToEpoch(val));
  };

  const handleCloseModalRS = () => {
    setShowMobileModalRS(false);
  };

  const handleRangeSelectSubmit = () => {
    console.log(startDateEpoch, endDateEpoch);
    if (startDateEpoch >= endDateEpoch) {
      console.log("End date must be ahead of start date!");
      setRsMessage("Invalid date range!");
      setTimeout(() => {
        setRsMessage("");
      }, 1500);
    } else if (isNaN(startDateEpoch) || isNaN(endDateEpoch)) {
      console.log("Invalid date range!");
      setRsMessage("Invalid date range!");
      setTimeout(() => {
        setRsMessage("");
      }, 1500);
    } else {
      setRangeSelModalFlag(true);
      setRsMessage("");
      handleCloseModalRS();
    }
  };

  const JSXContentChartRS = (
    <Fragment>
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>From</label>
        </div>
        <div className='column'>
          <KeyboardDatePicker
            format='DD/MM/yyyy'
            onChange={handleChangeStartDate}
            value={startDate}
            InputProps={{
              id: "dash-charts-fs-controls-mobile-custom-sel-date-from-input",
            }}
            KeyboardButtonProps={{
              id: "dash-charts-fs-controls-mobile-custom-sel-date-from-picker",
            }}
          />
        </div>
      </div>
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>To</label>
        </div>
        <div className='column'>
          <KeyboardDatePicker
            format='DD/MM/yyyy'
            onChange={handleChangeEndDate}
            value={endDate}
            InputProps={{
              id: "dash-charts-fs-controls-mobile-custom-sel-date-to-input",
            }}
            KeyboardButtonProps={{
              id: "dash-charts-fs-controls-mobile-custom-sel-date-to-picker",
            }}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='dash-charts-fs-controls-mobile-custom-sel-date-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "red",
                textAlign: "left",
                visibility: rsMessage !== "" ? "visible" : "hidden",
              }}>
              {rsMessage}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const handleCloseModalSettings = () => {
    setIsModalSettings(!isModalSettings);
    handleConfigBack();
  };

  const handleCloseModalSettingsAfterSuccess = () => {
    setIsModalSettings(false);
  };

  const runGetDeviceConfigFromConfig = () => {
    setIsRetry(true);
    // This will call a useEffect to run getDeviceData func from child <Config />
  };

  // Remote Setpoint Value
  const JSXRemoteSP = (
    <>
      <div className='columns '>
        <div className='column flex-start'>
          <span style={{ fontWeight: "normal" }}>
            Please enter a remote setpoint value from 0 to 100%
          </span>
        </div>
      </div>
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>Remote Setpoint</label>
        </div>
        <div className='column'>
          <input
            ref={SPInput}
            id='home-edit-device-modal-device-serial-input'
            className='input'
            type='text'
            inputMode='numeric'
            maxLength={3}
            onKeyDown={(e) => {
              if (
                !/[0-9]|Backspace|Tab|Enter|Delete|ArrowLeft|ArrowRight/.test(
                  e.key
                )
              ) {
                e.preventDefault();
              }
            }}
            onChange={(e) => handleChangeRemoteSP(e.target.value)}
            value={remoteSPVal}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='home-edit-device-modal-device-serial-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "red",
                textAlign: "left",
                visibility: !validateSN ? "visible" : "hidden",
              }}>
              {validateSNErrMsg}
            </p>
          </div>
        </div>
      </div>
    </>
  );

  const handleChangeRemoteSP = (val) => {
    setRemoteSPVal(val);
  };

  const handleCloseModalRemoteSP = () => {
    setIsModalRemoteSP(false);
    setRemoteSPVal("");
    setSelectedDelDeviceLabel("");
  };

  const submitRemoteSP = () => {
    // TEMP
    console.log("Remote SP value is " + remoteSPVal);
    setRemoteSPModalLoading(true);
    setTimeout(() => {
      setRemoteSPModalLoading(false);
      setRemoteSPModalError(true);
    }, 2000);
    setTimeout(() => {
      setRemoteSPModalError(false);
    }, 3000);
  };

  return (
    <Fragment>
      <div className='device-wrapper'>
        <SwitchTransition mode='out-in'>
          <CSSTransition
            key={
              props.isConfig
                ? transitionStateConfig
                : props.isDashboard
                ? transitionStateDash
                : null
            }
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames='slide'>
            {transitionStateConfig && transitionStateDash ? (
              !props.isMobile ? (
                <Scrollbars
                  autoHeight
                  id='device-list-scrollbar'
                  style={{
                    maxHeight: props.isMobile
                      ? "initial"
                      : "calc(100vh - 300px)",
                  }}
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        backgroundColor: "gray",
                        borderRadius: "30px",
                      }}
                    />
                  )}>
                  <Accordion id='device-list' defaultActiveKey={accOpenEvtKey}>
                    {renderedList}
                    {props.virtualDeviceList &&
                      props.virtualDeviceList.length !== 0 &&
                      props.virtualDeviceList[0].smartmeters.length !== 0 &&
                      ["P1", "ILM", "LAN"].map((obj) => {
                        return renderedVirtList(obj);
                      })}
                  </Accordion>
                </Scrollbars>
              ) : (
                <Fragment>
                  <Accordion id='device-list' defaultActiveKey={accOpenEvtKey}>
                    {renderedList}
                    {props.virtualDeviceList &&
                      props.virtualDeviceList.length !== 0 &&
                      props.virtualDeviceList[0].smartmeters.length !== 0 &&
                      ["P1", "ILM", "LAN"].map((obj) => {
                        return renderedVirtList(obj);
                      })}
                  </Accordion>
                </Fragment>
              )
            ) : transitionStateConfig && !transitionStateDash ? (
              <Dashboard
                dashboardType={dashboardType}
                selectedConfigDeviceData={selectedConfigDeviceData}
                handleDashboardBack={handleDashboardBack}
                setAnimExit={props.setAnimExit}
                isMobile={props.isMobile}
                isTablet={props.isTablet}
                isDashboard={props.isDashboard}
                getDeviceList={props.getDeviceList}
                setShowMobileModalRS={setShowMobileModalRS}
                startDateEpoch={startDateEpoch}
                endDateEpoch={endDateEpoch}
                rangeSelModalFlag={rangeSelModalFlag}
                setRangeSelModalFlag={setRangeSelModalFlag}
                closeDashboardWS={closeDashboardWS}
              />
            ) : (
              <Config
                ref={configRef}
                selectedConfigDeviceData={selectedConfigDeviceData}
                handleConfigBack={handleConfigBack}
                setAnimExit={props.setAnimExit}
                showMobileModal={showMobileModalDays}
                setShowMobileModal={setShowMobileModalDays}
                repeatDaysModal={repeatDaysModal}
                setRepeatDaysModal={setRepeatDaysModal}
                repeatDaysRecIndexModal={repeatDaysRecIndexModal}
                setRepeatDaysRecIndexModal={setRepeatDaysRecIndexModal}
                isMobile={props.isMobile}
                setIsModalSettings={setIsModalSettings}
                isRetry={isRetry}
                setIsRetry={setIsRetry}
                setFetchRetryLoading={setFetchRetryLoading}
                setFetchRetrySuccess={setFetchRetrySuccess}
                setFetchRetryError={setFetchRetryError}
                handleCloseModalSettingsAfterSuccess={
                  handleCloseModalSettingsAfterSuccess
                }
              />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
      {/* Delete Modal Desktop */}
      <Modal
        title={`Delete ${selectedDelDeviceType} Device`}
        content={JSXContentDel}
        actionName='Delete'
        isModal={isModalDel}
        handleCloseModal={handleCloseModalDel}
        actionFn={deleteDevice}
        loading={delLoading}
        error={delError}
        success={delSuccess}
        actionBtnId='home-del-device-modal-action-btn'
        cancelBtnId='home-del-device-modal-cancel-btn'
      />
      {/* Delete Modal Mobile */}
      <MobileModal
        title={`Delete ${selectedDelDeviceType} Device`}
        content={JSXContentDel}
        actionName='Delete'
        showMobileModal={showMobileModalDel}
        setShowMobileModal={setShowMobileModalDel}
        handleCloseModal={handleCloseModalDel}
        actionFn={deleteDevice}
        loading={delLoading}
        error={delError}
        success={delSuccess}
        actionBtnId='home-del-device-modal-action-btn-mobile'
        cancelBtnId='home-del-device-modal-cancel-btn-mobile'
      />
      {/* Edit Modal Desktop */}
      <Modal
        title={`Edit ${selectedEditDeviceType} Device`}
        content={JSXContentEd}
        isModal={isModalEd}
        handleCloseModal={handleCloseModalEd}
        actionFn={editDevice}
        loading={editLoading}
        error={editError}
        success={editSuccess}
        disabled={
          !validateLabel &&
          !validateSN &&
          editedDeviceParentId === initEditedDeviceParentId
        }
        errMsg={props.submitErrMsg}
        actionBtnId='home-edit-device-modal-action-btn'
        cancelBtnId='home-edit-device-modal-cancel-btn'
      />
      {/* Edit Modal Mobile */}
      <MobileModal
        title={`Edit ${selectedEditDeviceType} Device`}
        content={JSXContentEd}
        showMobileModal={showMobileModalEd}
        setShowMobileModal={setShowMobileModalEd}
        handleCloseModal={handleCloseModalEd}
        actionFn={editDevice}
        loading={editLoading}
        error={editError}
        success={editSuccess}
        disabled={
          !validateLabel &&
          !validateSN &&
          editedDeviceParentId === initEditedDeviceParentId
        }
        errMsg={props.submitErrMsg}
        actionBtnId='home-edit-device-modal-action-btn-mobile'
        cancelBtnId='home-edit-device-modal-cancel-btn-mobile'
      />
      {/* Add SM Modal Desktop */}
      <Modal
        title='Add SM Device'
        content={JSXContentAddSM}
        isModal={isModalAddSM}
        handleCloseModal={handleCloseModalAddSM}
        actionFn={addSMDevice}
        loading={fetchLoading}
        error={fetchError}
        success={fetchSuccess}
        disabled={!validateLabel || !validateSN}
        errMsg={props.submitErrMsg}
        actionBtnId='home-add-sm-device-modal-action-btn'
        cancelBtnId='home-add-sm-device-modal-cancel-btn'
      />
      {/* Add SM Modal Mobile */}
      <MobileModal
        title='Add SM Device'
        content={JSXContentAddSM}
        showMobileModal={showMobileModalAddSM}
        setShowMobileModal={setShowMobileModalAddSM}
        handleCloseModal={handleCloseModalAddSM}
        actionFn={addSMDevice}
        loading={fetchLoading}
        error={fetchError}
        success={fetchSuccess}
        disabled={!validateLabel || !validateSN}
        errMsg={props.submitErrMsg}
        actionBtnId='home-add-sm-device-modal-action-btn-mobile'
        cancelBtnId='home-add-sm-device-modal-cancel-btn-mobile'
      />
      {/* External Days Mobile modal */}
      <MobileModal
        showMobileModal={showMobileModalDays}
        setShowMobileModal={setShowMobileModalDays}
        title='Select Days'
        content={JSXContentRepeatDays}
        handleCloseModal={handleCloseModalDays}
        actionFn={handleSaveDays}
        disabled={repeatDaysModal.length === 0}
        actionBtnId='config-time-repeat-days-modal-action-btn-mobile'
        cancelBtnId='config-time-repeat-days-modal-cancel-btn-mobile'
      />
      {/* Failed to load settings modal */}
      <Modal
        title='Error!'
        content='Failed to load settings. Please try again.'
        actionName='Retry'
        isModal={isModalSettings}
        handleCloseModal={handleCloseModalSettings}
        actionFn={runGetDeviceConfigFromConfig}
        loading={fetchRetryLoading}
        error={fetchRetryError}
        success={fetchRetrySuccess}
        actionBtnId='config-failed-to-load-settings-modal-action-btn-mobile'
        cancelBtnId='config-failed-to-load-settings-modal-cancel-btn-mobile'
      />
      {/* Charts Range Selector Mobile modal */}
      <MobileModal
        showMobileModal={showMobileModalRS}
        setShowMobileModal={setShowMobileModalRS}
        title='Select Date Range'
        content={JSXContentChartRS}
        actionName='Submit'
        handleCloseModal={handleCloseModalRS}
        actionFn={handleRangeSelectSubmit}
        actionBtnId='dash-charts-fs-controls-mobile-action-btn'
        cancelBtnId='dash-charts-fs-controls-mobile-cancel-btn'
      />
      {/* Remote setpoint modal */}
      <Modal
        title={"Enter a remote setpoint for " + selectedRemSPDeviceLabel}
        content={JSXRemoteSP}
        actionName='Submit'
        isModal={isModalRemoteSP}
        handleCloseModal={handleCloseModalRemoteSP}
        actionFn={submitRemoteSP}
        loading={remoteSPModalLoading}
        error={remoteSPModalError}
        success={remoteSPModalSuccess}
        disabled={remoteSPVal === "" || remoteSPVal > 100}
        actionBtnId='config-remote-setpoint-modal-action-btn-mobile'
        cancelBtnId='config-remote-setpoint-modal-cancel-btn-mobile'
      />
      {/* User manual PDF link */}
      {!props.isDashboard && !props.isConfig && (
        <div id='user-manual-pdf-link'>
          <div id='user-manual-pdf-link-content'>
            <svg
              width='18'
              height='22'
              viewBox='0 0 18 22'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <g clip-path='url(#clip0_301_2)'>
                <path
                  d='M9.9728 4C10.5088 4 10.7768 4.3648 10.7768 4.7828C10.7768 5.3048 10.3112 5.7876 9.7052 5.7876C9.1976 5.7876 8.9016 5.4876 8.9156 4.9916C8.9156 4.5744 9.268 4 9.9728 4ZM8.3236 12C7.9004 12 7.5904 11.7392 7.8864 10.5904L8.372 8.5536C8.4564 8.228 8.4704 8.0972 8.372 8.0972C8.2452 8.0972 7.6964 8.322 7.3712 8.544L7.16 8.192C8.1888 7.3176 9.3724 6.8052 9.8804 6.8052C10.3032 6.8052 10.3736 7.3144 10.1624 8.0972L9.606 10.238C9.5076 10.616 9.5496 10.7464 9.6484 10.7464C9.7752 10.7464 10.1912 10.5896 10.6 10.2636L10.84 10.5892C9.8392 11.608 8.746 12 8.3236 12Z'
                  fill='#202B33'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M6.94501 0.25H11.055C12.423 0.25 13.525 0.25 14.392 0.367C15.292 0.487 16.05 0.747 16.652 1.348C17.254 1.95 17.512 2.708 17.634 3.608C17.75 4.475 17.75 5.578 17.75 6.945V15.055C17.75 16.422 17.75 17.525 17.634 18.392C17.513 19.292 17.254 20.05 16.652 20.652C16.05 21.254 15.292 21.512 14.392 21.634C13.525 21.75 12.422 21.75 11.055 21.75H6.94501C5.57801 21.75 4.47501 21.75 3.60801 21.634C2.70801 21.512 1.95001 21.254 1.34801 20.652C0.747009 20.05 0.488009 19.292 0.367009 18.392C0.326731 18.0789 0.299374 17.7643 0.285009 17.449C0.244797 17.3221 0.239272 17.1868 0.269009 17.057C0.252525 16.3898 0.24619 15.7224 0.250009 15.055V6.945C0.250009 5.578 0.250009 4.475 0.367009 3.608C0.487009 2.708 0.747009 1.95 1.34901 1.348C1.95001 0.746 2.70901 0.488 3.60901 0.367C4.47501 0.25 5.57801 0.25 6.94501 0.25ZM1.77701 17.25C1.79201 17.603 1.81601 17.914 1.85301 18.192C1.95201 18.926 2.13301 19.314 2.40901 19.591C2.68601 19.868 3.07501 20.048 3.80901 20.147C4.56401 20.248 5.56501 20.25 7.00001 20.25H11C12.436 20.25 13.437 20.248 14.192 20.147C14.926 20.048 15.314 19.867 15.592 19.591C15.788 19.395 15.935 19.142 16.04 18.75H5.00001C4.8011 18.75 4.61033 18.671 4.46968 18.5303C4.32903 18.3897 4.25001 18.1989 4.25001 18C4.25001 17.8011 4.32903 17.6103 4.46968 17.4697C4.61033 17.329 4.8011 17.25 5.00001 17.25H16.223C16.242 16.819 16.248 16.325 16.249 15.75H4.89801C3.92001 15.75 3.57801 15.756 3.31601 15.827C2.96319 15.9213 2.63861 16.1 2.3703 16.3477C2.102 16.5955 1.89803 16.9048 1.77601 17.249L1.77701 17.25ZM16.25 14.25H4.78201C3.96401 14.25 3.40601 14.25 2.92701 14.378C2.50578 14.4908 2.10748 14.6763 1.75001 14.926V7C1.75001 5.565 1.75201 4.563 1.85301 3.808C1.95201 3.074 2.13301 2.686 2.40901 2.409C2.68601 2.132 3.07501 1.952 3.80901 1.853C4.56401 1.752 5.56501 1.75 7.00001 1.75H11C12.436 1.75 13.437 1.752 14.192 1.853C14.926 1.952 15.314 2.133 15.592 2.409C15.868 2.686 16.048 3.074 16.147 3.809C16.249 4.563 16.25 5.565 16.25 7V14.25Z'
                  fill='#202B33'
                />
              </g>
              <defs>
                <clipPath id='clip0_301_2'>
                  <rect width='18' height='22' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <Link
              to={{
                pathname:
                  "https://dev.smartzee.com/static_files/SmartZee%20User%20Manual-Draft%20130623.pdf",
              }}
              target='_blank'>
              <span>Download User Manual</span>
            </Link>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ListView;
